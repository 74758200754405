import { useMutation } from '@apollo/client'
import { deserializePin, parsePinToParts } from '@faceup/crypto'
import { useContext, useState } from 'react'
import { EmailNotificationsCheckboxAbstract } from '../../../../../Components/EmailNotificationsCheckboxAbstract'
import { UserContext } from '../../../../../Contexts/UserContext'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { useCustomValues } from '../../../../../hooks/useCustomValues'
import { usePreLogin } from '../../../../../hooks/usePreLogin'

const fragments = {
  NotifyMe_publicReportSource: graphql(`
    fragment NotifyMe_publicReportSource on PublicReportSource {
      id
      ...EmailNotificationsCheckbox_publicReportSource
      config {
        ... on FormReportSourceConfiguration {
          id
          emailNotificationEnabled
        }
      }
    }
  `),
}

const mutations = {
  notifyMe: graphql(`
    mutation NotifyMeMutation($input: EditEmailCompanyVictimAnonymousInput!) {
      editEmailCompanyVictimAnonymous(input: $input) {
        companyVictim {
          id
        }
      }
    }
  `),
}

type NotifyMeProps = {
  publicReportSource: FragmentType<typeof fragments.NotifyMe_publicReportSource>
  reportPin: string
}

export const NotifyMe = ({ publicReportSource: _publicReportSource, reportPin }: NotifyMeProps) => {
  const publicReportSource = getFragmentData(
    fragments.NotifyMe_publicReportSource,
    _publicReportSource
  )
  const { reportSource } = useContext(UserContext)
  const preLogin = usePreLogin()
  const [insertedEmail, setInsertedEmail] = useState<string | null>(null)
  const customValues = useCustomValues()

  const [notify] = useMutation(mutations.notifyMe)

  const isEmailNotificationEnabled =
    publicReportSource.config?.__typename !== 'FormReportSourceConfiguration' ||
    (publicReportSource.config?.emailNotificationEnabled &&
      customValues?.emailNotificationEnabled === undefined) ||
    customValues?.emailNotificationEnabled === true

  if (!isEmailNotificationEnabled) {
    return null
  }

  return (
    <div className='px-14 py-8'>
      <EmailNotificationsCheckboxAbstract
        publicChannel={publicReportSource}
        isEmailNotificationEnabled={isEmailNotificationEnabled}
        onSubmit={async values => {
          const pin = deserializePin(reportPin)
          const { identity, version } = parsePinToParts(pin)
          const { passwordKeyPrehash } = await preLogin.performPreLogin(reportPin)
          const { data } = await notify({
            variables: {
              input: {
                email: values.email,
                reportPassword: reportSource?.password,
                passwordPrehash: passwordKeyPrehash,
                version,
                identity,
                organizationalUnitId: reportSource?.institutionId ?? '',
              },
            },
          })

          if (data) {
            if (values.email) {
              setInsertedEmail(values.email)
            } else {
              setInsertedEmail(null)
            }
            return true
          }
          return false
        }}
        email={insertedEmail}
        isEmailVerified={false}
      />
    </div>
  )
}
