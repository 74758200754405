import type { CaseStatus } from '@faceup/utils'
import { defineMessages } from '../TypedIntl'

export const sharedMessages = defineMessages({
  apiError: 'Shared.global.apiError',
  attachmentsTooManyFiles: 'Shared.attachments.tooManyFiles',
  attachmentsTooLargeFile: 'Shared.attachments.tooLargeFile',
  metadataRemoved: 'Shared.attachments.metadataRemoved',
  metadataUnableToRemove: 'Shared.attachments.metadataUnableToRemove',
  unsupportedMimetype: 'Shared.attachments.unsupportedMimetype',
  submit: 'Shared.action.send',
  save: 'Shared.action.save',
  savedMessage: 'FollowUp.form.savedMessage',
  add: 'Shared.action.add',
  invalidInputError: 'Shared.global.invalidInput',
  continue: 'FollowUp.global.continue',
  fieldRequired: 'Shared.global.invalidInput',
  selectPlaceholder: 'Shared.global.select.placeholder',
  encryptionError: 'Shared.global.encryptionError',
  globalNameLabel: 'Shared.global.name',

  // action
  send: 'Shared.action.send',
  delete: 'Shared.action.delete',
  download: 'Shared.action.download',
  ok: 'Shared.ok',
  cancel: 'Shared.cancel',

  bio: 'Shared.bio',
  partnerBio: 'Shared.bio.partner',
})

export const reportStatusMessages = defineMessages<CaseStatus>({
  Archived: 'Shared.report.status.Archived',
  Closed: 'Shared.report.status.Closed',
  Open: 'Shared.report.status.Open',
})
