import { TextUrlfy } from '@faceup/ui'
import { ChannelPageType, ChannelType } from '@faceup/utils'
import { type ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useCustomValues } from '../../../hooks/useCustomValues'
import { useFilledPage } from '../../Survey/useFilledPage'

const messages = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'Shared.surveys.pages.afterSend.content',
  [ChannelType.AutomatedHotline]: 'Shared.CompanyReportSent.description',
  [ChannelType.LiveHotline]: 'Shared.CompanyReportSent.description',
  [ChannelType.ReportingSystem]: 'Shared.CompanyReportSent.description',
})

const fragments = {
  useReportSentData_publicReportSource: graphql(`
    fragment useReportSentData_publicReportSource on PublicReportSource {
      id
      sourceType
      ...useFilledPage_reportSource
      config {
        ... on FormReportSourceConfiguration {
          id
          reportSentDescription
        }
      }
    }
  `),
}

export const useReportSentData = (
  _reportSource: FragmentType<typeof fragments.useReportSentData_publicReportSource>
) => {
  const reportSource = getFragmentData(
    fragments.useReportSentData_publicReportSource,
    _reportSource
  )
  const customValues = useCustomValues()
  const config =
    reportSource.config.__typename === 'FormReportSourceConfiguration' ? reportSource.config : null
  const filledPage = useFilledPage({ survey: reportSource, step: ChannelPageType.AfterSend })

  const reportSentDescription: ReactNode = useMemo(() => {
    if (customValues?.description) {
      return <TextUrlfy text={customValues.description} />
    }
    if (config?.reportSentDescription) {
      return <TextUrlfy text={config.reportSentDescription} />
    }
    return reportSource.sourceType === ChannelType.Survey ? (
      filledPage?.content
    ) : (
      <FormattedMessage {...messages[reportSource.sourceType]} />
    )
  }, [
    config?.reportSentDescription,
    reportSource.sourceType,
    customValues?.description,
    filledPage?.content,
  ])

  return {
    reportSentDescription,
  }
}
