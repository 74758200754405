import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { Typography } from '@faceup/ui-base'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { useReportSentData } from '../../hooks'
import { ReportSentContent } from '../ReportSentContent'
import { PinForm } from './components'

export const fragments = {
  ReportSentGeneral_publicReportSource: graphql(`
    fragment ReportSentGeneral_publicReportSource on PublicReportSource {
      id
      sourceType

      ...useReportSentData_publicReportSource
      ...PinForm_publicReportSource
      ...ReportSentContent_publicReportSource
    }
  `),
}

type ReportSentGeneralProps = {
  reportSource: FragmentType<typeof fragments.ReportSentGeneral_publicReportSource>
}

export const ReportSentGeneral = ({ reportSource: _reportSource }: ReportSentGeneralProps) => {
  const reportSource = getFragmentData(
    fragments.ReportSentGeneral_publicReportSource,
    _reportSource
  )
  const { reportSentDescription } = useReportSentData(reportSource)

  return (
    <ReportSentContent channel={reportSource}>
      <PinForm reportSource={reportSource} />
      <div className='flex gap-2'>
        <div>
          <UntitledIcon icon={ulInfoCircle} />
        </div>
        <Typography.Paragraph>{reportSentDescription}</Typography.Paragraph>
      </div>
    </ReportSentContent>
  )
}
