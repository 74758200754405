import { useLocation } from '@faceup/router'

export const useSurveyPreview = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const hasPreviewParam = params.has('preview')

  return {
    isPreview: hasPreviewParam,
  }
}
