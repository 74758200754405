import { useQuery } from '@apollo/client'
import type { RouteCallback } from '@faceup/router'
import { ChannelType, type Institution, type Plan } from '@faceup/utils'
import { type ReactNode, createContext, useContext, useEffect } from 'react'
import { graphql } from '../__generated__'
import { getPageRouteCallback } from '../utils/urls'
import { LanguageContext } from './LanguageContext'
import { UserContext } from './UserContext'

const query = graphql(`
  query ReportSourceProviderQuery(
    $institutionId: UUID
    $password: GraphQLInstitutionPassword
  ) {
    publicReportSourceByReportPassword(
      institutionId: $institutionId
      password: $password
    ) {
      id
      languages
      defaultLanguage
      sourceType
      institution {
        id
        billing {
          id
          plan
        }
        isTesting
        type
        organizationalUnitName
        config {
          id
          institutionName
        }
      }
      pages {
        ... on Page {
          id
        }
      }
    }
  }
`)

type ReportSourceContextProps = {
  id: string
  plan: Plan | null
  isTesting: boolean | null
  type: Institution | null
  name: string | null
  institutionName: string
  homepage: RouteCallback
} | null

export const ReportSourceContext = createContext<ReportSourceContextProps>(null)

type ReportSourceProviderProps = {
  children: ReactNode
}

const ReportSourceProvider = ({ children }: ReportSourceProviderProps) => {
  const { reportSource: userInstitution } = useContext(UserContext)
  const { language, changeLanguage } = useContext(LanguageContext)
  const { data } = useQuery(query, {
    variables: {
      institutionId: userInstitution?.institutionId,
      ...(userInstitution?.password && { password: userInstitution.password }),
    },
    skip: !userInstitution,
  })

  const reportSource = data?.publicReportSourceByReportPassword
  useEffect(() => {
    if (reportSource?.languages) {
      const languages = reportSource.languages
      const isUserLanguageAvailableInInstitution = languages.includes(language)
      if (!isUserLanguageAvailableInInstitution) {
        changeLanguage(reportSource.defaultLanguage)
      }
    }
  }, [reportSource, changeLanguage, language])

  return (
    <ReportSourceContext.Provider
      value={
        reportSource
          ? {
              plan: reportSource.institution.billing?.plan ?? null,
              type: reportSource.institution.type ?? null,
              isTesting: reportSource.institution.isTesting ?? null,
              name: reportSource.institution.organizationalUnitName ?? null,
              institutionName: reportSource.institution.config?.institutionName ?? '',
              id: reportSource.id,
              homepage:
                reportSource.sourceType === ChannelType.Survey
                  ? routes => routes.survey(reportSource.id)
                  : reportSource.pages[0]?.__typename === 'Page'
                    ? (getPageRouteCallback(reportSource.pages[0]) ?? (routes => routes.home()))
                    : routes => routes.home(),
            }
          : null
      }
    >
      {children}
    </ReportSourceContext.Provider>
  )
}

export default ReportSourceProvider
