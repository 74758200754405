import { UntitledIcon } from '@faceup/icons'
import { ulBookmarkMinus } from '@faceup/icons/ulBookmarkMinus'
import { ulDotsHorizontal } from '@faceup/icons/ulDotsHorizontal'
import { ReportStatus } from '@faceup/report'
import { Card } from '@faceup/ui'
import { Button, Col, Dropdown, Row, Typography } from '@faceup/ui-base'
import moment from 'moment-timezone'
import type { SavedReport } from '../../../Contexts/SavedReportsProvider'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { useSavedReportsContext } from '../../../hooks/useSavedReportsContext'

const messages = defineMessages({
  unlist: 'FollowUp.report.unlist',
})

type ReportInfoProps = {
  report: SavedReport
  onClick: () => void
}

export const ReportInfo = (props: ReportInfoProps) => {
  const { report, onClick } = props
  const { removeReport } = useSavedReportsContext()

  return (
    <Card onClick={onClick}>
      <Row justify='space-between' className='mb-2'>
        <Col>
          <Typography.Text className='inline-flex'>
            <ReportStatus status={report.status} />
          </Typography.Text>
        </Col>
        <Col>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'unlist',
                  label: <FormattedMessage {...messages.unlist} />,
                  icon: <UntitledIcon icon={ulBookmarkMinus} />,
                  onClick: () => removeReport(report),
                },
              ],
            }}
          >
            <Button type='link' size='small' onClick={e => e.stopPropagation()}>
              <UntitledIcon icon={ulDotsHorizontal} />
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row className='mb-2'>
        <Col span={24}>
          <Typography.Title level={5} className='font-bold'>
            {report.category}
          </Typography.Title>
          <Typography.Text className='text-prussianBlue-600'>{report.content}</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography.Text className='text-prussianBlue-600 font-semibold'>
            {moment(report.createdAt).format('LLL')}
          </Typography.Text>
        </Col>
      </Row>
    </Card>
  )
}
