import styled from '@emotion/styled'
import { Button, Typography } from '@faceup/ui-base'
import { useContext, useState } from 'react'
import { LayoutContext, Theme } from '../../Contexts/LayoutContext'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import FaceUpLogo from '../../assets/logo-fu.svg'
import NNTBLogo from '../../assets/logo-nntb.svg'
import UserLogin from './UserLogin'
import welcomeImg from './assets/welcome.jpg'

const { Title } = Typography

const messages = defineMessages({
  header: 'FollowUp.welcome.header',
  description: 'FollowUp.welcome.description',
  begin: 'FollowUp.welcome.startButton',
})

const Landing = () => {
  const { theme } = useContext(LayoutContext)
  const {
    login: { hasAccount },
  } = useContext(UserContext)
  const [activeLogin, setActiveLogin] = useState(false)

  if (hasAccount || activeLogin) {
    return (
      <Wrapper>
        <UserLogin />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <TopWrapper>
        <img
          style={{ width: theme === Theme.NNTB ? '6rem' : '7rem' }}
          src={theme === Theme.NNTB ? NNTBLogo : FaceUpLogo}
          alt={theme}
        />
        <CenterImage src={welcomeImg} />
        <Title level={2}>
          <FormattedMessage {...messages.header} />
        </Title>
        <Description>
          <FormattedMessage {...messages.description} />
        </Description>
      </TopWrapper>
      <Button block type='primary' onClick={() => setActiveLogin(true)}>
        <FormattedMessage {...messages.begin} />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: nowrap column;
  justify-content: space-between;
  height: 100vh;
  max-width: 600px;
  width: 100%;
  margin: auto;
  padding: calc(20px + env(safe-area-inset-top)) 20px calc(20px + env(safe-area-inset-bottom));
`

const CenterImage = styled.img`
  margin: 50px 0 32px;
  width: 100%;
`

const TopWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  width: 100%;
`

const Description = styled.div`
  font-size: 16px;
  margin-top: 14px;
`

export default Landing
