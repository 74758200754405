import { useNavigate } from '@faceup/router'
import { Form, Modal, type TextProps, Typography } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import { type ReactNode, useContext, useState } from 'react'
import PageTitle from '../../Components/PageTitle'
import PlainLayout from '../../Components/PlainLayout'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { UserContext, type UserInstitution } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import useConfigForProject from '../../hooks/useConfigForProject'
import useAnalytics from '../../utils/analytics'
import QrScanner from './QrCode/QrScanner'
import SearchCompanyForm from './SearchCompanyForm'
import SearchSchoolForm from './SearchSchoolForm'
import { SendUnregisteredSchoolTipModal } from './SendUnregisteredSchoolTipModal'

const { Text } = Typography

const messages = defineMessages({
  title: 'FollowUp.SearchInstitution.title',
  forSchools: 'FollowUp.SearchInstitution.forSchools',
  forCompanies: 'FollowUp.SearchInstitution.forCompanies',
  unknownPin: 'FollowUp.SearchInstitution.unknownPin',
  unknownPinTitle: 'FollowUp.SearchInstitution.unknownPin.title',
  unknownPin0: 'FollowUp.SearchInstitution.unknownPin.0',
  unknownPin1: 'FollowUp.SearchInstitution.unknownPin.1',
  unknownPin2: 'FollowUp.SearchInstitution.unknownPin.2',
  scanQrCode: 'FollowUp.SearchInstitution.scanQrCode',
  cannotSeeMySchool: 'FollowUp.SearchInstitution.cannotSeeMySchool',
})

const SearchInstitution = () => {
  const { setReportSource } = useContext(UserContext)
  const { language, changePreviousLanguage } = useContext(LanguageContext)
  const [institutionType, setInstitutionType] = useState(Institution.School)
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false)
  const { trackScanQRCodeWindowOpened } = useAnalytics()
  const [isUnknownPinModalOpen, setIsUnknownPinModalOpen] = useState(false)

  // send unregistered school modal
  const [isUnregisteredSchoolModalOpen, setIsUnregisteredSchoolModalOpen] = useState(false)
  const { isSk } = useConfigForProject()

  const navigate = useNavigate()

  const proceedToNextSite = (institution: UserInstitution) => {
    changePreviousLanguage(language)
    setReportSource(institution)
    navigate(routes => routes.page())
  }

  if (isQRScannerOpen) {
    return <QrScanner close={() => setIsQRScannerOpen(false)} />
  }

  return (
    <PlainLayout>
      <div className='flex flex-nowrap justify-center items-center flex-1 flex-col'>
        <div className='flex flex-nowrap flex-col max-w-[40rem] w-full'>
          <PageTitle
            style={{ marginBottom: 0, textAlign: 'center' }}
            data-test='search-institution-title'
          >
            <FormattedMessage {...messages.title} />
          </PageTitle>
          <div className='bg-[#f0f3f4] p-0.5 rounded-lg flex justify-center w-max mx-auto mb-6 max-w-full'>
            <InstitutionButton
              active={institutionType === Institution.School}
              dataTest='search-institution-select-schools'
              onClick={() => setInstitutionType(Institution.School)}
            >
              <FormattedMessage {...messages.forSchools} />
            </InstitutionButton>
            <InstitutionButton
              dataTest='search-institution-select-companies'
              active={institutionType === Institution.Company}
              onClick={() => setInstitutionType(Institution.Company)}
            >
              <FormattedMessage {...messages.forCompanies} />
            </InstitutionButton>
          </div>
          <Form layout='vertical' style={{ width: '100%' }}>
            {institutionType === Institution.School ? (
              <SearchSchoolForm proceed={proceedToNextSite} />
            ) : (
              <SearchCompanyForm proceed={proceedToNextSite} />
            )}
          </Form>
          <Text
            strong
            className='text-center mb-6 cursor-pointer'
            // override ant styles
            style={{ color: '#0e9af7' }}
            onClick={() => {
              setIsQRScannerOpen(true)
              trackScanQRCodeWindowOpened()
            }}
          >
            <FormattedMessage {...messages.scanQrCode} />
          </Text>
        </div>
      </div>
      {institutionType === Institution.School ? (
        // if the user is choosing a school on a .sk domain
        // display a "I don't see my school" message
        // otherwise, display placeholder, so the layout does not jump
        // when toggling between schools and organizations
        isSk ? (
          <>
            <BottomText strong onClick={() => setIsUnregisteredSchoolModalOpen(true)}>
              <FormattedMessage {...messages.cannotSeeMySchool} />
            </BottomText>
            <SendUnregisteredSchoolTipModal
              open={isUnregisteredSchoolModalOpen}
              onClose={() => setIsUnregisteredSchoolModalOpen(false)}
            />
          </>
        ) : (
          <BottomText>&nbsp;</BottomText>
        )
      ) : (
        <BottomText strong onClick={() => setIsUnknownPinModalOpen(true)}>
          <FormattedMessage {...messages.unknownPin} />
        </BottomText>
      )}
      <Modal
        open={isUnknownPinModalOpen}
        onClose={() => setIsUnknownPinModalOpen(false)}
        title={<FormattedMessage {...messages.unknownPinTitle} />}
      >
        <div>
          <div>
            <FormattedMessage {...messages.unknownPin0} />
          </div>
          <div>
            <FormattedMessage {...messages.unknownPin1} />
          </div>
          <div>
            <FormattedMessage {...messages.unknownPin2} />
          </div>
        </div>
      </Modal>
    </PlainLayout>
  )
}

const InstitutionButton = ({
  active,
  onClick,
  dataTest,
  children,
}: { active: boolean; onClick: () => void; dataTest: string; children: ReactNode }) => {
  return (
    <button
      className={`outline-none border-0 transition duration-300 p-2 px-5 font-semibold rounded-lg cursor-pointer ${active ? 'bg-white' : ''} m-0`}
      data-test={dataTest}
      onClick={onClick}
      type='button'
    >
      {children}
    </button>
  )
}

const BottomText = ({ children, ...props }: { children: ReactNode } & TextProps) => (
  <Text
    className='mt-auto text-center mb-0 cursor-pointer'
    // override ant styles
    style={{ color: '#0e9af7' }}
    {...props}
  >
    {children}
  </Text>
)

export default SearchInstitution
