import { UntitledIcon } from '@faceup/icons'
import { ulMenu01 } from '@faceup/icons/ulMenu01'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Button } from '@faceup/ui-base'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import LanguageDropdown from '../../../../LanguageDropdown/LanguageDropdown'
import { LogoutButton } from '../../../../LogoutButton'
import { PartnerInstitutionLogo } from './PartnerInstitutionLogo'

const fragments = {
  HeaderComplexWithoutPages_reportSource: graphql(`
    fragment HeaderComplexWithoutPages_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...PartnerInstitutionLogo_reportSource
    }
  `),
}

type HeaderComplexWithoutPagesProps = {
  reportSource: FragmentType<typeof fragments.HeaderComplexWithoutPages_reportSource>
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithoutPages = ({
  reportSource: _reportSource,
  isMenuOpened,
  setIsMenuOpened,
}: HeaderComplexWithoutPagesProps) => {
  const reportSource = getFragmentData(
    fragments.HeaderComplexWithoutPages_reportSource,
    _reportSource
  )
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <div className='flex items-center justify-between w-full'>
      <div className='flex-1' />
      <div className='flex flex-1 justify-center'>
        <PartnerInstitutionLogo reportSource={reportSource} />
      </div>
      <div className='flex flex-1 justify-end'>
        <div className='hidden sm:flex'>
          {reportSource.languages.length > 1 && (
            <LanguageDropdown
              availableLanguages={reportSource.languages ?? []}
              defaultLanguage={reportSource.defaultLanguage}
            />
          )}
          {isApp && <LogoutButton />}
        </div>
        <div className='sm:hidden'>
          <Button
            type='text'
            icon={<UntitledIcon icon={isMenuOpened ? ulXClose : ulMenu01} size={24} />}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
          />
        </div>
      </div>
    </div>
  )
}
