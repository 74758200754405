import { usePageTemplate } from '@faceup/ui'
import type { ChannelPageType } from '@faceup/utils'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'

const fragments = {
  useFilledPage_reportSource: graphql(`
    fragment useFilledPage_reportSource on PublicReportSource {
      id
      pages(language: $language) {
        ... on ChannelPage {
          id
          type
          content
          title
        }
      }
    }
  `),
}

type UseFilledPageProps = {
  survey: FragmentType<typeof fragments.useFilledPage_reportSource>
  step: ChannelPageType
}
export const useFilledPage = ({ survey: _survey, step }: UseFilledPageProps) => {
  const survey = getFragmentData(fragments.useFilledPage_reportSource, _survey)
  const { fillObject } = usePageTemplate()

  const currentPage = survey.pages.find(page =>
    page.__typename === 'Page' ? false : page.type === step
  )

  if (!currentPage) {
    return null
  }

  // @ts-expect-error TODO
  return currentPage.__typename === 'ChannelPage' ? fillObject(currentPage) : undefined
}
