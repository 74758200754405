import { generateVoiceRecordingName } from '@faceup/utils'
import { type ReactNode, useState } from 'react'
import { type RecordType, RecordingStatus, ReportFormContext } from './ReportFormContext'

type Props = {
  children: ReactNode
}

const ReportFormProvider = ({ children }: Props) => {
  const [victimPin, setVictimPin] = useState<string | null>(null)
  const [createdAt, setCreatedAt] = useState<Date | null>(null)
  const [recording, setRecording] = useState<RecordType | null>(null)
  const [recordingStatus, setRecordingStatus] = useState(RecordingStatus.Idle)
  const [tag, setTag] = useState<string | null>(null)
  const [isAnonymous, setIsAnonymous] = useState(true)

  const buildRecordingFile = async () => {
    if (!recording) {
      return null
    }

    return new File([recording.data], generateVoiceRecordingName(), { type: 'audio/mpeg' })
  }

  return (
    <ReportFormContext.Provider
      value={{
        victimPin,
        setVictimPin,
        createdAt,
        setCreatedAt,
        recording,
        setRecording,
        buildRecordingFile,
        recordingStatus,
        setRecordingStatus,
        tag,
        setTag,
        isAnonymous,
        setIsAnonymous,
      }}
    >
      {children}
    </ReportFormContext.Provider>
  )
}

export default ReportFormProvider
