import { Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'

const messages = defineMessages({
  title: 'FollowUp.pageNotFound.title',
  description: 'FollowUp.pageNotFound.description',
})

const PageNotFound = () => (
  <>
    <Typography.Title className='text-center'>
      <FormattedMessage {...messages.title} />
    </Typography.Title>
    <Typography.Text className='text-center'>
      <FormattedMessage {...messages.description} />
    </Typography.Text>
  </>
)

export default PageNotFound
