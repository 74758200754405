import { Clipboard } from '@capacitor/clipboard'
import { UntitledIcon } from '@faceup/icons'
import { ulCopy01 } from '@faceup/icons/ulCopy01'
import { Prompt, UnstyledButton, ucLoader } from '@faceup/ui'
import { Button, Space, Typography, useMessage, useUiBaseConfig } from '@faceup/ui-base'
import { ChannelType } from '@faceup/utils'
import { Suspense as ReactSuspense, lazy, useContext, useState } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { ReportFormContext } from '../../../../../Contexts/ReportFormContext'
import { sharedMessages } from '../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import useAnalytics from '../../../../../utils/analytics'
import { copyTextForOldBrowsers } from '../../../../../utils/helpers'
import { NotifyMe } from './NotifyMe'

const PDFReportPin = lazy(() => import('./PDFReportPin'))

const { Text } = Typography

const messages = defineMessages({
  pinPrompt: 'FollowUp.CreateReportForm.pinFormPrompt',
  pinLabel: 'FollowUp.CheckReport.pinLabel',
  pinCopy: 'Shared.action.copy',
  pinCopied: 'Shared.global.copied',
  checkReport: 'FollowUp.CheckReport.title',
  beforeLeaveDescription: 'FollowUp.ReportSent.beforeLeave.description',
})

const messagesPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.ReportSent.pinDisclaimer.survey',
  [ChannelType.AutomatedHotline]: 'FollowUp.ReportSent.pinDisclaimer',
  [ChannelType.LiveHotline]: 'FollowUp.ReportSent.pinDisclaimer',
  [ChannelType.ReportingSystem]: 'FollowUp.ReportSent.pinDisclaimer',
})

const fragments = {
  PinForm_publicReportSource: graphql(`
    fragment PinForm_publicReportSource on PublicReportSource {
      id
      shortLink
      sourceType

      ...NotifyMe_publicReportSource
    }
  `),
}

type PinFormProps = {
  reportSource: FragmentType<typeof fragments.PinForm_publicReportSource>
}

export const PinForm = ({ reportSource: _reportSource }: PinFormProps) => {
  const reportSource = getFragmentData(fragments.PinForm_publicReportSource, _reportSource)
  const { formatMessage } = useIntl()
  const { victimPin, createdAt, tag } = useContext(ReportFormContext)
  const [pinPrompt, setPinPrompt] = useState(true)
  const { client } = useContext(LayoutContext)
  const message = useMessage()
  const { trackReportPinCopied, trackReportPinDownloaded } = useAnalytics()
  const { colors } = useUiBaseConfig()

  const savePinToClipboard = async () => {
    trackReportPinCopied()
    // if client is JOBKA, they are unable to copy PIN in webview, that means we need to call a custom function, which they inject into the page
    if (client === 'jobka') {
      if (window?.copyToClipboard) {
        // JOBKA custom function - declaration in `typings.d.ts`
        window?.copyToClipboard({ Text: victimPin ?? '' })
        message.success(formatMessage(messages.pinCopied))
        setPinPrompt(false)
        return
      }
      console.error('Function is not implemented')
    }

    try {
      await Clipboard.write({ string: victimPin ?? '' })
      message.success(formatMessage(messages.pinCopied))
      setPinPrompt(false)
    } catch {
      const copied = copyTextForOldBrowsers(victimPin ?? '')
      if (copied) {
        message.success(formatMessage(messages.pinCopied))
        setPinPrompt(false)
      } else {
        message.error(formatMessage(sharedMessages.apiError))
      }
    }
  }

  return (
    <div
      style={{
        border: `1px solid ${colors.primary1}`,
        borderRadius: '0.75rem',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          backgroundColor: colors.primary1,
          padding: '1.5rem',
          paddingTop: '3rem',
          paddingInline: '2rem',
        }}
      >
        <div className='flex flex-col gap-6'>
          <div>
            <Text strong style={{ textAlign: 'center', display: 'block' }}>
              <FormattedMessage {...messagesPerChannel[reportSource.sourceType]} />
            </Text>
          </div>
          <UnstyledButton
            onClick={() => savePinToClipboard()}
            className='bg-white rounded-md text-center w-full'
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: 600,
                textAlign: 'center',
                display: 'block',
              }}
              className='sensitive py-2'
            >
              {victimPin}
            </Text>
          </UnstyledButton>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
            <Button
              type='primary'
              block
              data-test='report-sent-pin-copy'
              onClick={() => savePinToClipboard()}
            >
              <Space>
                <UntitledIcon icon={ulCopy01} />
                <FormattedMessage {...messages.pinCopy} />
              </Space>
            </Button>

            <ReactSuspense
              fallback={
                <Button type='primary' block>
                  <Space>
                    <UntitledIcon icon={ucLoader} isSpinning />
                    <FormattedMessage {...sharedMessages.download} />
                  </Space>
                </Button>
              }
            >
              <PDFReportPin
                pin={victimPin}
                createdAt={createdAt}
                shortId={reportSource?.shortLink ?? ''}
                tag={tag ?? ''}
                onClick={() => {
                  trackReportPinDownloaded()
                  setPinPrompt(false)
                }}
                channelType={reportSource.sourceType}
              />
            </ReactSuspense>
          </div>
        </div>
        <Prompt when={pinPrompt} message={formatMessage(messages.pinPrompt)} />
      </div>

      <NotifyMe publicReportSource={reportSource} reportPin={victimPin ?? ''} />
    </div>
  )
}
