import type { UntitledIconData } from '../../UntitledIconData'

const ulMenu01: UntitledIconData = {
  prefix: 'ul',
  name: 'menu-01',
  paths: [
    {
      shape: 'M3 12H21M3 6H21M3 18H21',
    },
  ],
}

export default ulMenu01
