import { useMutation } from '@apollo/client'
import { ChannelType } from '@faceup/utils'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'
import { EmailNotificationsCheckboxAbstract } from './EmailNotificationsCheckboxAbstract'

const fragments = {
  EmailNotificationsCheckbox_victim: graphql(`
    fragment EmailNotificationsCheckbox_viewer on CompanyVictim {
      email
      isEmailVerified
      case {
        channel {
          id
          sourceType
          ...EmailNotificationsCheckbox_reportSource
          config {
            __typename
            ... on FormReportSourceConfiguration {
              id
              emailNotificationEnabled
            }
          }
        }
      }
    }
  `),
}

const mutations = {
  EditEmailCompanyVictim: graphql(`
    mutation EditEmailCompanyVictimMutation($input: EditEmailCompanyVictimInput!) {
      editEmailCompanyVictim(input: $input) {
        companyVictim {
          id
          email
        }
      }
    }
  `),
}

type Props = {
  viewer: FragmentType<typeof fragments.EmailNotificationsCheckbox_victim> | null
}

const EmailNotificationsCheckbox = ({ viewer: _viewer }: Props) => {
  const viewer = getFragmentData(fragments.EmailNotificationsCheckbox_victim, _viewer)
  const [editCompanyEmail] = useMutation(mutations.EditEmailCompanyVictim)
  const channel = viewer?.case?.channel
  return (
    channel && (
      <div className='max-w-[400px]'>
        <EmailNotificationsCheckboxAbstract
          channel={channel}
          isEmailNotificationEnabled={
            channel.sourceType === ChannelType.Survey ||
            (channel.config.__typename === 'FormReportSourceConfiguration'
              ? channel.config.emailNotificationEnabled
              : false)
          }
          onSubmit={async ({ email }) => {
            const { data } = await editCompanyEmail({
              variables: {
                input: {
                  email,
                },
              },
            })
            return Boolean(data)
          }}
          email={viewer?.email ?? null}
          isEmailVerified={viewer?.isEmailVerified ?? undefined}
        />
      </div>
    )
  )
}

export default EmailNotificationsCheckbox
