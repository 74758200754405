import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from '@faceup/router'
import { HtmlRenderer, usePageTemplate } from '@faceup/ui'
import { Skeleton, Typography } from '@faceup/ui-base'
import { convertBasenameToLanguage } from '@faceup/utils'
import { useContext, useEffect } from 'react'
import { MenuItems } from '../../Components/MenuItems'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { UserContext } from '../../Contexts/UserContext'
import { graphql } from '../../__generated__'
import PageNotFound from './Components/PageNotFound'

const query = graphql(`
  query PageViewQuery(
    $id: PageGlobalId!
    $reportSourceId: PublicReportSourceGlobalId!
  ) {
    page(pageId: $id) {
      id
      content
      language
    }
    publicReportSource(reportSourceId: $reportSourceId) {
      id
      isDeleted
      isAnonymityEnabled
      isDefault
      ...InstitutionLayoutMenuItems_reportSource
    }
  }
`)

const PageView = () => {
  const { id } = useParams<{ id: string }>()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const { reportSource } = useContext(UserContext)
  const navigate = useNavigate()
  const { basename } = useContext(LanguageContext)
  const { client } = useContext(LayoutContext)
  const { setIsAnonymous } = useContext(ReportFormContext)

  const isApp = client === 'app'

  const { data, loading } = useQuery(query, {
    variables: {
      id: id ?? '',
      reportSourceId: reportSource?.defaultFormId ?? '',
    },
    skip: !reportSource?.defaultFormId,
  })

  const page = data?.page
  const reportSourceData = data?.publicReportSource

  useEffect(() => {
    const isPageLoaded = page !== undefined
    const doesPageCorrespondsWithLanguage = convertBasenameToLanguage(basename) === page?.language
    if (isPageLoaded && !doesPageCorrespondsWithLanguage) {
      navigate(routes => routes.page())
    }
  }, [basename, navigate, page])

  useEffect(() => {
    if (typeof reportSourceData?.isAnonymityEnabled === 'boolean') {
      setIsAnonymous(reportSourceData.isAnonymityEnabled)
    }
  }, [reportSourceData?.isAnonymityEnabled, setIsAnonymous])

  useEffect(() => {
    setIsDefaultForm(reportSourceData?.isDefault ?? false)
  }, [reportSourceData?.isDefault, setIsDefaultForm])

  const isDeleted = reportSourceData?.isDeleted

  if (data && (!page || isDeleted)) {
    return <PageNotFound />
  }

  return (
    <div className='sm:min-h-[calc(100vh-250px)]'>
      <div>
        {loading || !page ? (
          <div className='flex flex-col gap-8'>
            <Typography.Title className='text-center'>
              <Skeleton />
            </Typography.Title>
            <Skeleton />
          </div>
        ) : (
          <div className='w-full'>
            <div
              data-test='pages-content'
              style={{
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
              }}
            >
              <HtmlRenderer html={fillObject(page).content} />
            </div>
            <div className='hidden sm:block'>
              {reportSourceData && (
                <div className={`sticky bottom-0 ${isApp ? 'hidden' : ''}`}>
                  <div
                    style={{
                      background: '#fff',
                      paddingBlock: 16,
                      borderTop: `1px solid #9DB0DF1A`,
                    }}
                  >
                    <MenuItems reportSource={reportSourceData} direction='horizontal' />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PageView
