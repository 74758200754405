import { useMutation } from '@apollo/client'
import { createReportWithVictim, serializePin } from '@faceup/crypto'
import { type AnyObject, Form, useForm, yup } from '@faceup/form'
import { useNavigate, useSearchParams } from '@faceup/router'
import { Flex, Typography, notification, useMessage } from '@faceup/ui-base'
import {
  FormItemType,
  type Language,
  SURVEY_SOURCE_PARAM_NAME,
  SurveySource,
  getTranslation,
} from '@faceup/utils'
import { useContext, useEffect, useRef } from 'react'
import {
  SurveyMultipleChoice,
  SurveyScaleChoice,
  SurveySingleChoice,
  SurveyTextInput,
} from '../../Components/Survey'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useCookie } from '../../utils/useCookie'
import useReportAuth from '../../utils/useReportAuth'

const fragments = {
  CreateSurveyForm_surveyChannelConfiguration: graphql(`
    fragment CreateSurveyForm_surveyChannelConfiguration on SurveyChannelConfiguration {
      id
      formItems {
        id
        type
        isRequired
        order
        labelTranslations {
          translation
          language
        }
        hintTranslations {
          translation
          language
        }
        maxResponses
        minResponses
        maxLength
        options {
          id
          order
          labelTranslations {
            translation
            language
          }
        }
        scaleStartLabelTranslations {
          translation
          language
        }
        scaleEndLabelTranslations {
          translation
          language
        }
        ...UseCreateYupSchema_formItem
      }
    }
  `),
  UseCreateYupSchema_formItem: graphql(`
    fragment UseCreateYupSchema_formItem on FormItem {
      id
      type
      isRequired
      maxLength
      maxResponses
      minResponses
      options {
        id
        labelTranslations {
          translation
          language
        }
      }
    }
  `),
  CreateSurveyForm_institution: graphql(`
    fragment CreateSurveyForm_institution on PublicCompany {
      id
      isE2EE
      recipients(caseType: Submission) {
        id
        publicKey
      }
    }
  `),
  CreateSurveyForm_system: graphql(`
    fragment CreateSurveyForm_system on System {
      id
      publicKey
    }
  `),
}

const mutations = {
  createSubmission: graphql(`
    mutation CreateSurveySubmission($input: CreateSurveySubmissionInput!) {
      createSurveySubmission(input: $input) {
        victimIdentity
        createdCase {
          id
          tag
        }
      }
    }
  `),
}

const messages = defineMessages({
  surveyNotSent: 'FollowUp.surveys.questions.notSent',
  questionsTitle: 'FollowUp.surveys.questions.title',
  isRequired: 'Shared.global.invalidInput',
  fillAllRequiredFields: 'Shared.global.fillAllRequiredFields',
  minLimit: 'FollowUp.surveys.questions.error.minLimit',
  maxLimit: 'FollowUp.surveys.questions.error.maxLimit',
})

type FormItems = FragmentType<typeof fragments.UseCreateYupSchema_formItem>[]

const useCreateYupSchema = (_formItems: FormItems) => {
  const formItems = getFragmentData(fragments.UseCreateYupSchema_formItem, _formItems)
  const { formatMessage } = useIntl()
  const schema = formItems.reduce((accSchema, formItem) => {
    const { type, isRequired, id, maxLength, maxResponses, minResponses } = formItem

    const getValidationForFormItem = () => {
      switch (type) {
        case FormItemType.SimpleText: {
          let simpleTextSchema = yup.string()
          if (maxLength) {
            simpleTextSchema = simpleTextSchema.max(maxLength)
          }
          if (isRequired) {
            simpleTextSchema = simpleTextSchema.required()
          }

          return simpleTextSchema
        }
        case FormItemType.Scale:
          return isRequired
            ? yup
                .string()
                .required()
                .oneOf(
                  formItem.options.map(option => option.labelTranslations[0]?.translation ?? '')
                )
            : yup
                .string()
                .oneOf(
                  formItem.options.map(option => option.labelTranslations[0]?.translation ?? '')
                )
        case FormItemType.Select:
          return isRequired
            ? yup
                .string()
                .required()
                .oneOf(formItem.options.map(option => option.id))
            : yup.string().oneOf(formItem.options.map(option => option.id))
        case FormItemType.MultiSelect:
          return isRequired
            ? yup
                .array()
                .of(yup.string().oneOf(formItem.options.map(option => option.id)))
                .required()
                .min(
                  minResponses ?? 1,
                  formatMessage(messages.minLimit, { count: minResponses ?? 1 })
                )
                .max(maxResponses ?? Number.POSITIVE_INFINITY, formatMessage(messages.maxLimit))
            : yup
                .array()
                .of(yup.string().oneOf(formItem.options.map(option => option.id)))
                .test(
                  'min-limit',
                  formatMessage(messages.minLimit, { count: minResponses ?? 1 }),
                  values => {
                    const isValid =
                      minResponses === null ||
                      values?.length === 0 ||
                      (!!values?.length && values.length >= minResponses)
                    return isValid
                  }
                )
                .test(
                  'max-limit',
                  formatMessage(messages.maxLimit, { count: maxResponses }),
                  values => {
                    const isValid =
                      maxResponses === null ||
                      values?.length === 0 ||
                      (!!values?.length && values.length <= maxResponses)
                    return isValid
                  }
                )
      }
      return
    }
    const validator = getValidationForFormItem()

    return {
      ...accSchema,
      [id]: validator,
    }
  }, {})

  return yup.object().shape(schema)
}

type CreateSurveyFormProps = {
  isPreview: boolean
  surveyConfig: FragmentType<typeof fragments.CreateSurveyForm_surveyChannelConfiguration>
  institution: FragmentType<typeof fragments.CreateSurveyForm_institution>
  system: FragmentType<typeof fragments.CreateSurveyForm_system>
  surveyId: string
  surveyDefaultLanguage: Language
}

/**
 * CreateSubmissionForm actually.
 */
export const CreateSurveyForm = ({
  isPreview,
  surveyConfig: _surveyConfig,
  institution: _institution,
  system: _system,
  surveyId,
  surveyDefaultLanguage,
}: CreateSurveyFormProps) => {
  const { logout } = useReportAuth()
  const getTimeElapsed = useTimeElapsed()
  const { setVictimPin, setCreatedAt, setTag } = useContext(ReportFormContext)
  const navigate = useNavigate()
  const [_, setSurveyFilled] = useCookie(`survey-${surveyId}`)

  const system = getFragmentData(fragments.CreateSurveyForm_system, _system)
  const institution = getFragmentData(fragments.CreateSurveyForm_institution, _institution)
  const surveyConfig = getFragmentData(
    fragments.CreateSurveyForm_surveyChannelConfiguration,
    _surveyConfig
  )
  const message = useMessage()
  const { formatMessage } = useIntl()

  const formItems = surveyConfig.formItems
  const schema = useCreateYupSchema(formItems)

  const form = useForm<{ [id: string]: AnyObject }>({
    schema,
    afterSubmit: 'persistValues',
  })

  const surveySource = useSurveySource()

  const [createSubmission] = useMutation(mutations.createSubmission, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0 && form.formState.isSubmitted) {
      message.error(formatMessage(messages.fillAllRequiredFields))
    }
  }, [form.formState.errors, form.formState.isSubmitted, formatMessage, message.error])

  return (
    <Flex vertical gap={64} style={{ width: '100%' }}>
      <Flex justify='center'>
        <Typography.Title level={3}>
          <FormattedMessage {...messages.questionsTitle} />
        </Typography.Title>
      </Flex>
      <Form
        buttonsPosition='under-center'
        submitButtonText='send'
        isUnsavedAlertDisabled={isPreview}
        form={form}
        isSuccessMessageDisplayed={false}
        onSubmit={async values => {
          if (isPreview) {
            setVictimPin('1111 2222 3333 4444')
            setCreatedAt(new Date())
            setTag('preview')

            navigate(routes => routes.sentReport(), {
              search: '?preview=',
            })

            message.warning(formatMessage(messages.surveyNotSent))

            return true
          }

          const payload = await createReportWithVictim(
            {
              moreInfo: '',
              victimName: '',
              attachments: [],
            },
            institution.recipients.map(member => ({
              id: member.id,
              key: member.publicKey ?? '',
            })),
            institution?.isE2EE ? undefined : system.publicKey
          )

          if (payload.isErr()) {
            console.error(payload.error.message)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
              description: payload.error.message,
            })
            return false
          }

          const {
            victimPin,
            privateKeyEncrypted,
            passwordPrehash,
            recipientKeys,
            nonce,
            salt,
            publicKey,
            senderKey,
          } = payload.value

          const result = await createSubmission({
            variables: {
              input: {
                reportSourceId: surveyId,
                answers: Object.entries(values)?.map(([key, value]) => ({
                  formItemId: key,
                  values: Array.isArray(value) ? value : [value],
                })),
                durationInMs: getTimeElapsed(),
                surveySource,
                privateKeyEncrypted,
                passwordPrehash,
                recipientKeys,
                senderKey,
                publicKey,
                nonce,
                salt,
              },
            },
          })
          if (result.errors) {
            return false
          }

          const identity = result?.data?.createSurveySubmission?.victimIdentity ?? ''
          const pin = serializePin(identity + victimPin)
          setSurveyFilled(String(true))
          await logout()

          setVictimPin(pin)
          setCreatedAt(new Date())
          setTag(result?.data?.createSurveySubmission?.createdCase?.tag ?? '')
          navigate(routes => routes.sentReport())

          return true
        }}
      >
        <Flex vertical gap={64} className='mb-[24px] text-start'>
          {formItems.map(formItem => {
            const { id, type, options, maxResponses } = formItem
            const label = getTranslation(
              formItem.labelTranslations,
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )
            const hint = getTranslation(
              formItem.hintTranslations ?? [],
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )
            const optionLabels = options.map(option => ({
              label: option.labelTranslations[0]?.translation ?? '',
              value: option.id,
            }))
            const startLabel = getTranslation(
              formItem.scaleStartLabelTranslations ?? [],
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )
            const endLabel = getTranslation(
              formItem.scaleEndLabelTranslations ?? [],
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )

            switch (type) {
              case FormItemType.SimpleText:
                return (
                  <SurveyTextInput
                    name={id}
                    key={id}
                    label={label}
                    description={hint}
                    control={form.control}
                  />
                )
              case FormItemType.Select:
                return (
                  <SurveySingleChoice
                    key={id}
                    name={id}
                    label={label}
                    description={hint}
                    control={form.control}
                    options={optionLabels}
                  />
                )
              case FormItemType.MultiSelect:
                return (
                  <SurveyMultipleChoice
                    key={id}
                    name={id}
                    label={label}
                    description={hint}
                    control={form.control}
                    options={optionLabels}
                    maxResponses={maxResponses}
                  />
                )
              case FormItemType.Scale:
                return (
                  <SurveyScaleChoice
                    key={id}
                    name={id}
                    label={label}
                    description={hint}
                    control={form.control}
                    options={optionLabels}
                    startLabel={startLabel}
                    endLabel={endLabel}
                  />
                )
              default:
                return null
            }
          })}
        </Flex>
      </Form>
    </Flex>
  )
}

const surveySourceSchema = yup.string().oneOf(Object.values(SurveySource)).required()

// hook that extracts the survey source from the URL
const useSurveySource = (): SurveySource => {
  const [params] = useSearchParams()

  const surveySourceValue = params.get(SURVEY_SOURCE_PARAM_NAME) ?? SurveySource.Direct

  try {
    return surveySourceSchema.validateSync(surveySourceValue)
  } catch {
    return SurveySource.Unknown
  }
}

// hook that can be used to get elapsed time after a component was mounted
const useTimeElapsed = () => {
  const startTime = useRef(Date.now())

  const getTimeElapsed = () => {
    return Date.now() - startTime.current
  }

  return getTimeElapsed
}
