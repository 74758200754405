import {
  type FieldValues,
  type FormItemWrapperProps,
  type Path,
  type PathValue,
  type UseControllerProps,
  useCustomController,
} from '@faceup/form'
import { FormItem } from '@faceup/ui'
import { Flex, Typography, useUiBaseConfig } from '@faceup/ui-base'

type Option = {
  label: string
  value: string
}

type UiSelectProps<T> = {
  options: T[]
}

type SurveyScaleChoiceProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<UiSelectProps<Option> & FormItemWrapperProps, 'value' | 'defaultValue'> & {
    startLabel: string
    endLabel: string
  }

type OptionComponentProps = {
  option: Option
  isActive: boolean
  onClick: (event: React.MouseEvent) => void
}

export const ScaleOptionComponent: React.FC<OptionComponentProps> = ({
  option,
  isActive,
  onClick,
}) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: `1px solid ${token.colorPrimary}`,
    ...activeColor,
  }

  return (
    <Flex
      style={{
        borderRadius: token.borderRadiusLG,
        backgroundColor: '#FFFFFF',
        border: `1px solid ${token.colorBorder}`,
        padding: '16px',
        height: '44px',
        width: '44px',
        ...(isActive ? activeStyles : {}),
      }}
      align='center'
      justify='center'
      onClick={onClick}
      className='cursor-pointer'
    >
      <Typography.Text
        style={{
          wordBreak: 'keep-all',
          ...(isActive ? activeColor : {}),
        }}
      >
        {option.label}
      </Typography.Text>
    </Flex>
  )
}

export const SurveyScaleChoice = <T extends FieldValues>(props: SurveyScaleChoiceProps<T>) => {
  const {
    formItemProps,
    inputProps: { value, onChange },
  } = useCustomController({ ...props, defaultValue: undefined as PathValue<T, Path<T>> })

  const selectOption = (event: React.MouseEvent, optionValue: string) => {
    event.preventDefault()
    onChange(value === optionValue ? undefined : optionValue)
  }

  const { options, startLabel, endLabel } = props

  return (
    <FormItem {...formItemProps} variant='black'>
      <Flex gap={12} vertical>
        <Flex gap={6} wrap>
          {startLabel && (
            <Typography.Text>
              {options?.[0]?.label} - {startLabel}
              {startLabel && endLabel && ','}
            </Typography.Text>
          )}
          {endLabel && (
            <Typography.Text>
              {options?.[options.length - 1]?.label} - {endLabel}
            </Typography.Text>
          )}
        </Flex>
        <Flex gap={8} wrap>
          {options.map(option => {
            const isActive = value === option.label
            return (
              <ScaleOptionComponent
                key={option.value}
                option={option}
                isActive={isActive}
                onClick={event => {
                  selectOption(event, option.label)
                }}
              />
            )
          })}
        </Flex>
      </Flex>
    </FormItem>
  )
}
