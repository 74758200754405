import { useCallback, useState } from 'react'

const separator = '; '

export const useCookie = (cookieName: string) => {
  const [cookieValue, setCookieValue] = useState(() => {
    const cookies = document.cookie.split(separator).reduce<Record<string, string>>((acc, curr) => {
      const [key, value] = curr.split('=')
      if (!key) {
        return acc
      }
      acc[key] = value ?? ''
      return acc
    }, {})

    return cookies[encodeURIComponent(cookieName)]
  })

  const setCookie = useCallback(
    (value: string) => {
      const expires = new Date()
      expires.setFullYear(expires.getFullYear() + 1) // arbitrary value

      const cookieParts = [
        `${encodeURIComponent(cookieName)}=${encodeURIComponent(value)}`,
        'path=/',
        `expires=${expires.toUTCString()}`,
        'secure',
        'SameSite=Strict',
      ]

      document.cookie = cookieParts.join(separator)

      setCookieValue(value)
    },
    [cookieName]
  )

  return [cookieValue, setCookie] as const
}
