import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Button } from '@faceup/ui-base'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../../Contexts/LayoutContext'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../__generated__'
import LanguageDropdown from '../../../../../LanguageDropdown'
import { LogoutButton } from '../../../../../LogoutButton'
import { PartnerInstitutionLogo } from '../PartnerInstitutionLogo'
import { PagesMenu } from './components'

const fragments = {
  HeaderComplexWithPages_reportSource: graphql(`
    fragment HeaderComplexWithPages_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...PartnerInstitutionLogo_reportSource
      ...PagesMenu_reportSource
    }
  `),
}

type HeaderComplexWithPagesProps = {
  reportSource: FragmentType<typeof fragments.HeaderComplexWithPages_reportSource>
  isMenuOpened: boolean
  setIsMenuOpened: (isOpened: boolean) => void
}

export const HeaderComplexWithPages = ({
  isMenuOpened,
  setIsMenuOpened,
  reportSource: _reportSource,
}: HeaderComplexWithPagesProps) => {
  const reportSource = getFragmentData(fragments.HeaderComplexWithPages_reportSource, _reportSource)
  const { client } = useContext(LayoutContext)
  const isApp = client === 'app'

  return (
    <div className='flex items-center justify-between w-full'>
      <div className='flex flex-1 justify-center'>
        <div className='flex items-center'>
          <PartnerInstitutionLogo reportSource={reportSource} />
        </div>
        <div className='hidden sm:flex flex-1 mx-16 overflow-hidden'>
          <PagesMenu reportSource={reportSource} />
        </div>
        <div className='hidden sm:flex items-center'>
          {reportSource.languages.length > 1 && (
            <LanguageDropdown
              availableLanguages={reportSource.languages}
              defaultLanguage={reportSource.defaultLanguage}
            />
          )}
          {isApp && <LogoutButton />}
        </div>
        <div className='sm:hidden'>
          <Button
            type='text'
            icon={<UntitledIcon icon={ulArrowLeft} size={24} />}
            onClick={() => setIsMenuOpened(!isMenuOpened)}
          />
        </div>
      </div>
    </div>
  )
}
