type MantineSize = 'sm' | 'md'

export const sizeSettings = {
  lg: 21,
  md: 18,
  sm: 20,
  xs: 24,
}

export const maxAppWidth = 1350

export const headerPadding = 32

export const desktopBreakpoint = 'sm'
export type LayoutVariant = 'simple' | 'complex'
export type HeaderVariant = 'complex' | 'simple' | 'simple-no-back-button'

export const breakpoints = {
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px',
}

export const mq = {
  // show on breakpoint to ♾️
  smUp: `@media (min-width: ${breakpoints.sm}px)`,
  mdUp: `@media (min-width: ${breakpoints.md}px)`,
  lgUp: `@media (min-width: ${breakpoints.lg}px)`,
  xlUp: `@media (min-width: ${breakpoints.xl}px)`,
}

export const panelWidth: Record<LayoutVariant, Partial<Record<MantineSize, number>>> = {
  simple: {
    sm: 50,
    md: 150,
  },
  complex: {
    sm: 100,
    md: 261,
  },
}
