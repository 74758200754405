import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usMicrophone01 } from '@faceup/icons/usMicrophone01'
import { usTrash04 } from '@faceup/icons/usTrash04'
import { Button } from '@faceup/ui-base'
import moment from 'moment-timezone'
import { useContext } from 'react'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'

const messages = defineMessages({
  startVoiceRecording: 'FollowUp.voiceRecording.button.start',
  recorded: 'FollowUp.voiceRecording.button.recorded',
})

type Props = {
  open: () => void
}

const VoiceRecordingButton = ({ open }: Props) => {
  const { recording, setRecording } = useContext(ReportFormContext)

  const isRecorded = Boolean(recording)
  const readableDuration = moment.utc(recording?.duration ?? 0).format(`m:ss`)

  return (
    <Wrapper>
      <div className='rounded-2xl bg-white flex'>
        <Button
          className='bg-primary! text-primary border-0!'
          onClick={() => open()}
          icon={<UntitledIcon icon={usMicrophone01} fontSize={18} />}
          size='small'
          shape='round'
        >
          {isRecorded ? (
            <>
              <FormattedMessage {...messages.recorded} /> - {readableDuration}
            </>
          ) : (
            <FormattedMessage {...messages.startVoiceRecording} />
          )}
        </Button>
      </div>
      {isRecorded && <Basket icon={usTrash04} onClick={() => setRecording(null)} />}
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
  transition: 0.3s;
  background: #ff4d4f;
`

const Basket = styled(UntitledIcon)`
  font-size: 1.1rem;
  padding: 0 0.5rem 0 0.25rem;
  color: #fff;
`

export default VoiceRecordingButton
