import { Divider } from '@faceup/ui-base'
import { useElementSize } from '@mantine/hooks'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import LanguageDropdown from '../../../LanguageDropdown'
import { MenuItems } from '../../../MenuItems'

const fragments = {
  InstitutionLayoutWebFooter_reportSource: graphql(`
    fragment InstitutionLayoutWebFooter_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...InstitutionLayoutMenuItems_reportSource
    }
  `),
}

type WebFooterProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutWebFooter_reportSource>
  shouldShowLanguagesDropdown: boolean
}

export const WebFooter = ({
  reportSource: _reportSource,
  shouldShowLanguagesDropdown,
}: WebFooterProps) => {
  const reportSource = getFragmentData(
    fragments.InstitutionLayoutWebFooter_reportSource,
    _reportSource
  )
  const { ref: refFooter, height: heightFooter } = useElementSize()
  const { ref: refLanguageDropdown, height: heightLanguageDropdown } = useElementSize()
  const height = heightFooter + (shouldShowLanguagesDropdown ? heightLanguageDropdown : 0)

  return (
    <div style={{ height }} className='fixed bottom-0 left-0 right-0'>
      <Divider />
      <div
        ref={refLanguageDropdown}
        className={
          shouldShowLanguagesDropdown ? '' : 'invisible absolute pointer-events-none opacity-0'
        }
      >
        <div className='px-6 py-3'>
          <LanguageDropdown
            availableLanguages={reportSource.languages}
            defaultLanguage={reportSource.defaultLanguage}
          />
        </div>
        <Divider />
      </div>

      <div ref={refFooter}>
        <div className='p-6 bg-white'>
          <MenuItems reportSource={reportSource} direction='vertical' />
        </div>
      </div>
    </div>
  )
}
