import { useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { UserProvider } from '@faceup/member'
import { Chat, ChatInput, useUnreadFollowUpMessages } from '@faceup/report'
import { Link, Navigate, useNavigate } from '@faceup/router'
import { Card } from '@faceup/ui'
import { Button, Col, Row, Skeleton, Space, Typography } from '@faceup/ui-base'
import { ChannelType, omitNullInArray } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { type ComponentProps, useContext, useMemo } from 'react'
import PageTitle from '../../Components/PageTitle'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { HideMedia } from '../../mq'
import useAnalytics from '../../utils/analytics'
import useMobileFileDownload from '../../utils/useMobileFileDownload'
import useReportAuth from '../../utils/useReportAuth'
import ReportInfo from './ReportInfo'

const { Title } = Typography

const messages = defineMessages({
  deletedTitle: 'FollowUp.report.deleted.title',
  deletedButton: 'FollowUp.report.deleted.button',
  messages: 'Shared.chat.title',
  reopen: 'FollowUp.ReportStatusAlert.reopen',
  empty: 'FollowUp.chat.empty',
  writeNote: 'FollowUp.chat.write',
  noteLabel: 'Shared.chat.label',
  administrator: 'FollowUp.chat.administrator',
  leaveQuestion: 'FollowUp.chat.leaveQuestion',
  checkAnotherReport: 'FollowUp.report.checkAnotherReport',
  addAttachments: 'Shared.report.attachments.add',
  attachmentsTitle: 'Shared.report.attachments',
  detectLanguage: 'Shared.report.translate.detectLanguage',
  translate: 'Shared.report.translate.translate',
  showOriginal: 'Shared.report.translate.showOriginal',
  to: 'Shared.report.translate.to',
  sameLanguageError: 'Shared.report.translate.error.sameLanguages',
  disabledReasonE2EE: 'Shared.report.translate.error.disabledReasonE2EE',
  onUseWarningTitle: 'Shared.report.translate.onUseWarning.title',
  onUseWarningContent: 'Shared.report.translate.onUseWarning.content',
  onUseWarningDoNotShowAgain: 'Shared.report.translate.onUseWarning.doNotShowAgain',
})

const titlePerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.survey.title',
  [ChannelType.AutomatedHotline]: 'FollowUp.report.title',
  [ChannelType.LiveHotline]: 'FollowUp.report.title',
  [ChannelType.ReportingSystem]: 'FollowUp.report.title',
})

const closedMessagesPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.ReportStatusAlert.submissionWasClosed',
  [ChannelType.AutomatedHotline]: 'FollowUp.ReportStatusAlert.reportWasClosed',
  [ChannelType.LiveHotline]: 'FollowUp.ReportStatusAlert.reportWasClosed',
  [ChannelType.ReportingSystem]: 'FollowUp.ReportStatusAlert.reportWasClosed',
})

const archivedMessagesPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.ReportStatusAlert.submissionWasArchived',
  [ChannelType.AutomatedHotline]: 'FollowUp.ReportStatusAlert.reportWasArchived',
  [ChannelType.LiveHotline]: 'FollowUp.ReportStatusAlert.reportWasArchived',
  [ChannelType.ReportingSystem]: 'FollowUp.ReportStatusAlert.reportWasArchived',
})

type ReportQuery = ResultOf<typeof query.ReportQuery>

const query = {
  ReportQuery: graphql(`
    # not used but needed for useFormItemsAnswers_companyReport
    query ReportQuery($sourceLanguage: Language, $targetLanguage: Language) {
      companyVictimViewer {
        id
        __typename
        email
        isEmailVerified

        case {
          id
          isDeleted
          channel {
            id
            sourceType
          }
          ...CompanyReportInfo_case
          followUpActivities(page: 0, rowsPerPage: 10000) {
            __typename
            items {
              id
              ...useUnreadFollowUpMessages_followupComment
            }
          }
        }
        ...CompanyReportInfo_viewer
      }
    }
  `),
}

const Report = () => {
  const { logout, isAuthenticated } = useReportAuth()
  const { client } = useContext(LayoutContext)
  const navigate = useNavigate()
  const fileDownloadHandler = useMobileFileDownload()
  const { formatMessage } = useIntl()
  const { trackFollowupMessageSent } = useAnalytics()

  const { data, loading } = useQuery(query.ReportQuery, {
    fetchPolicy: 'network-only',
  })

  if (!isAuthenticated()) {
    return <Navigate to={routes => routes.checkReport()} replace />
  }

  const viewer = data?.companyVictimViewer ?? null
  const aCase = viewer?.case ?? null

  if (!viewer || !aCase) {
    return <Skeleton />
  }

  const channel = aCase.channel

  const sizeSettings = {
    lg: 24,
    xs: 24,
  }

  if (aCase.isDeleted) {
    return (
      <div>
        <UntitledIcon icon={ulTrash03} />
        <Title level={2}>
          <FormattedMessage {...messages.deletedTitle} />
        </Title>
        <Link to={routes => routes.checkReport()}>
          <Button ghost type='primary'>
            <FormattedMessage {...messages.deletedButton} />
          </Button>
        </Link>
      </div>
    )
  }

  return (
    <UserProvider
      application='follow-up'
      userId={viewer?.id ?? ''}
      onChangeLanguage={() => null}
      onLogout={() => null}
    >
      <Row justify='center'>
        <Col {...sizeSettings}>
          <PageTitle>
            <FormattedMessage {...titlePerChannel[channel.sourceType]} />
          </PageTitle>
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings} style={{ paddingBottom: '1em' }}>
          <ReportInfo loading={loading} case={aCase} viewer={viewer} />
        </Col>
      </Row>
      <Row justify='center'>
        <Col {...sizeSettings}>
          <Card loading={loading} className='p-6'>
            <Row gutter={24} style={{ marginBottom: 16 }}>
              <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Space size={24}>
                  <Title level={2} style={{ display: 'inline-block' }}>
                    <FormattedMessage {...messages.messages} />
                  </Title>
                </Space>
              </Col>
            </Row>
            <ChatWrapper
              onMessageSend={trackFollowupMessageSent}
              viewer={viewer}
              caseId={aCase.id}
              userId={viewer.id}
              relativeMessages={{
                type: 'Victim',
                ...messages,
                closedMessagesPerChannel,
                archivedMessagesPerChannel,
              }}
              {...(client === 'app' && { fileDownloadHandler })}
              input={props => (
                <ChatInput
                  {...props}
                  inputRef={null}
                  mentions={null}
                  placeholder={formatMessage(messages.writeNote)}
                />
              )}
            />
          </Card>
        </Col>
      </Row>
      <HideMedia hideWhen='lgUp'>
        <Row style={{ marginTop: 20 }}>
          <Col {...sizeSettings}>
            <div className='flex justify-center'>
              <Button
                type='link'
                onClick={() => {
                  logout()
                  navigate(routes => routes.checkReport(), { replace: true })
                }}
              >
                <FormattedMessage {...messages.checkAnotherReport} />
              </Button>
            </div>
          </Col>
        </Row>
      </HideMedia>
    </UserProvider>
  )
}

type ChatWrapperProps = { viewer: ReportQuery['companyVictimViewer'] } & Omit<
  ComponentProps<typeof Chat>,
  'firstUnreadMessageId'
>

const ChatWrapper = ({ viewer, ...props }: ChatWrapperProps) => {
  const { trackReportRatingSubmitted } = useAnalytics()

  const comments = useMemo(
    () => omitNullInArray(viewer?.case?.followUpActivities?.items ?? []),
    [viewer]
  )
  const { firstUnreadMessageId } = useUnreadFollowUpMessages(comments)
  return (
    <Chat
      {...props}
      firstUnreadMessageId={firstUnreadMessageId ?? ''}
      onRatingChange={trackReportRatingSubmitted}
    />
  )
}

export default Report
