import { UntitledIcon } from '@faceup/icons'
import { ulLogOut01 } from '@faceup/icons/ulLogOut01'
import { Button, type ButtonProps } from '@faceup/ui-base'
import { useContext } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  logout: 'Shared.logout',
})

type LogoutButtonProps = Pick<ButtonProps, 'size'>

export const LogoutButton = (props: LogoutButtonProps) => {
  const { logout } = useContext(UserContext)

  return (
    <Button {...props} type='text' icon={<UntitledIcon icon={ulLogOut01} />} onClick={logout}>
      <FormattedMessage {...messages.logout} />
    </Button>
  )
}
