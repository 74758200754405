import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulMessageCheckSquare } from '@faceup/icons/ulMessageCheckSquare'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulSearchSm } from '@faceup/icons/ulSearchSm'
import { type RouteCallback, useNavigate } from '@faceup/router'
import { Button, type ButtonProps } from '@faceup/ui-base'
import { ChannelType, Institution } from '@faceup/utils'
import { type ReactNode, useContext } from 'react'
import { UserContext } from '../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useInstitution } from '../../hooks/useInstitution'
import { useIsCheckReportAvailable } from '../../hooks/useIsCheckReportAvailable'
import { useSurveyPreview } from '../../hooks/useSurveyPreview'
import useAnalytics from '../../utils/analytics'
import { useCookie } from '../../utils/useCookie'

const fragments = {
  InstitutionLayoutMenuItems_reportSource: graphql(`
    fragment InstitutionLayoutMenuItems_reportSource on PublicReportSource {
      id
      sourceType
      ...IsCheckReportAvailable_reportSource
    }
  `),
}

const messages = defineMessages({
  createReport: 'FollowUp.InstitutionLayout.createReport',
  createReportForTestingSchool: 'FollowUp.InstitutionLayout.createReportForTestingSchool',
  backToSchoolList: 'FollowUp.InstitutionLayout.backToSchoolList',
  startSurvey: 'FollowUp.welcome.startButton',
})

const checkTitleMessagesPerChannel = defineMessages<ChannelType>({
  [ChannelType.ReportingSystem]: 'FollowUp.CheckReport.title',
  [ChannelType.LiveHotline]: 'FollowUp.CheckReport.title',
  [ChannelType.AutomatedHotline]: 'FollowUp.CheckReport.title',
  [ChannelType.Survey]: 'FollowUp.CheckReport.title.survey',
})

type MenuItemBase = {
  label: ReactNode
  visible?: boolean
  icon: UntitledIconData
  variant?: 'filled' | 'light'
  dataTest?: string
  key: string
}

type MenuItemLinkProp = MenuItemBase & {
  onClick?: () => void
  url: RouteCallback
}

type MenuItemButtonProp = MenuItemBase & {
  onClick: () => void
  url?: never
}

type MenuItem = MenuItemLinkProp | MenuItemButtonProp

type MenuItemsProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutMenuItems_reportSource>
  direction: 'horizontal' | 'vertical'
  startSurvey?: () => void
}

export const MenuItems = ({
  reportSource: _reportSource,
  direction: _direction,
  startSurvey,
}: MenuItemsProps) => {
  const { isPreview } = useSurveyPreview()

  const reportSource = getFragmentData(
    fragments.InstitutionLayoutMenuItems_reportSource,
    _reportSource
  )
  const { isCheckReportAvailable } = useIsCheckReportAvailable({ reportSource })
  const { logout } = useContext(UserContext)
  const institution = useInstitution()
  const navigate = useNavigate()

  const { trackCreateReportClicked } = useAnalytics()

  const isTestingSchool = institution?.isTesting === true && institution.type === Institution.School

  const [userFilledSurvey] = useCookie(`survey-${reportSource.id}`)

  const isSurvey = reportSource.sourceType === ChannelType.Survey
  const menuItems: MenuItem[] = [
    {
      key: 'createReport',
      url: routes => routes.createReport(),
      onClick: trackCreateReportClicked,
      icon: ulPlus,
      label: isTestingSchool ? (
        <FormattedMessage {...messages.createReportForTestingSchool} />
      ) : (
        <FormattedMessage {...messages.createReport} />
      ),
      variant: 'filled',
      visible: !isSurvey,
      dataTest: 'menu-item-create',
    },
    ...(typeof startSurvey === 'function'
      ? [
          {
            key: 'startSurvey',
            onClick: startSurvey,
            icon: ulPlus,
            label: <FormattedMessage {...messages.startSurvey} />,
            variant: 'filled' as const,
            dataTest: 'start-survey-button',
            visible: !userFilledSurvey || isPreview,
          },
        ]
      : []),
    {
      key: 'checkReport',
      url: routes => routes.checkReport(),
      icon: ulMessageCheckSquare,
      label: <FormattedMessage {...checkTitleMessagesPerChannel[reportSource.sourceType]} />,
      visible: isCheckReportAvailable,
      dataTest: 'menu-item-check',
    },
    {
      key: 'logout',
      onClick: logout,
      icon: ulSearchSm,
      label: <FormattedMessage {...messages.backToSchoolList} />,
      visible: isTestingSchool,
    },
  ]

  const filteredMenuItems = menuItems.filter(item => item.visible !== false)

  return (
    <div className='w-full flex flex-col sm:flex-row gap-4'>
      {filteredMenuItems.map(item => {
        const isFilled = item.variant === 'filled'

        const buttonProps: ButtonProps = {
          icon: <UntitledIcon icon={item.icon} size={19} />,
          type: 'primary',
          ghost: !isFilled,
        }
        if (item.url) {
          return (
            <div key={item.key} className='flex-1'>
              <Button
                {...buttonProps}
                onClick={() => {
                  navigate(item.url)
                  item.onClick?.()
                }}
                data-test={item.dataTest ?? undefined}
                className='w-full'
              >
                {item.label}
              </Button>
            </div>
          )
        }
        return (
          <div key={item.key} className='flex-1'>
            <Button
              key={item.key}
              onClick={item.onClick}
              data-test={item.dataTest ?? undefined}
              {...buttonProps}
              className='w-full'
            >
              {item.label}
            </Button>
          </div>
        )
      })}
    </div>
  )
}
