import { useQuery } from '@apollo/client'
import { RecipientsOnReportForm } from '@faceup/utils'
import { useEffect, useMemo, useState } from 'react'
import { graphql } from '../../../__generated__'

export type Recipient = {
  isSelected: boolean
  canBeRemoved: boolean
  canBeAdded: boolean
  isEditable: boolean
  id: string
  publicKey: string | null
  name: string | null
  isPartner: boolean
  bio: string | null
}

const query = graphql(`
  query useRecipientsQuery(
    $organizationalUnitId: UUID!
    $reportSourceId: PublicReportSourceGlobalId!
  ) {
    publicInstitution(institutionId: $organizationalUnitId) {
      id
      recipients(caseType: Report) {
        id
        publicKey
        name
        profileImageUrl
        bio
        isPartner
        accessibleCategories(reportSourceId: $reportSourceId) {
          id
        }
      }
    }
  }
`)

export const useRecipients = (
  selectedOrganizationalUnitId?: string,
  selectedCategoryId?: string,
  reportSourceId?: string,
  recipientsOnReportForm?: RecipientsOnReportForm | undefined
) => {
  const { data, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      organizationalUnitId: selectedOrganizationalUnitId ?? '',
      reportSourceId: reportSourceId ?? '',
    },
    skip: !selectedOrganizationalUnitId || !reportSourceId,
  })

  const initialState = useMemo(() => {
    if (!selectedCategoryId) {
      return []
    }

    const selectedInstitution = data?.publicInstitution

    return (
      selectedInstitution?.recipients
        .filter(
          member =>
            member.accessibleCategories == null ||
            member.accessibleCategories.map(category => category.id).includes(selectedCategoryId)
        )
        ?.map((member, _index, recipients) => {
          // we want to select by default => even if they are not visible they are still sent to server
          const isSelected = true
          const canBeRemoved =
            recipientsOnReportForm === RecipientsOnReportForm.Editable && recipients.length > 1
          const canBeAdded = !isSelected

          return {
            ...member,
            isSelected,
            isEditable:
              recipientsOnReportForm === RecipientsOnReportForm.Editable &&
              (canBeAdded || canBeRemoved),
            canBeRemoved,
            canBeAdded,
          }
        }) ?? []
    )
  }, [data?.publicInstitution, recipientsOnReportForm, selectedCategoryId])

  const [recipients, setRecipients] = useState<Recipient[]>(initialState)

  useEffect(() => {
    setRecipients(initialState)
  }, [initialState])

  return {
    recipients,
    areRecipientsLoading: loading,
    changeRecipient: (member: Recipient) => {
      if (
        !member.isEditable ||
        (!member.canBeAdded && !member.isSelected) ||
        (!member.canBeRemoved && member.isSelected)
      ) {
        return
      }
      setRecipients(previousRecipients => {
        const newRecipients = previousRecipients.map(pm => {
          const selected = pm.id === member.id ? !pm.isSelected : pm.isSelected
          const canBeAdded = !selected
          return {
            ...pm,
            canBeAdded,
            isSelected: selected,
          }
        })

        const canBeRemoved = newRecipients.filter(recipient => recipient.isSelected).length >= 2

        return newRecipients.map(recipient => ({
          ...recipient,
          isEditable:
            recipientsOnReportForm === RecipientsOnReportForm.Editable &&
            (recipient.canBeAdded || canBeRemoved),
          canBeRemoved,
        }))
      })
    },
  }
}
