import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { useMatchPath, useNavigate } from '@faceup/router'
import { pagesIcons, usePageTemplate } from '@faceup/ui'
import { Button, Dropdown, Row } from '@faceup/ui-base'
import type { PageIcon } from '@faceup/utils'
import { useElementSize } from '@mantine/hooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, defineMessages } from '../../../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../../__generated__'
import { getPageRouteCallback } from '../../../../../../../utils/urls'
import { MenuItem } from './components'
import { countVisibleElements } from './utils'

const messages = defineMessages({
  moreMenuItems: 'FollowUp.layout.menu.moreMenuItems',
})

const fragments = {
  PagesMenu_reportSource: graphql(`
    fragment PagesMenu_reportSource on PublicReportSource {
      id
      isDefault
      pages(language: $language) {
        ... on Page {
          id
          icon
          title
        }
      }
    }
  `),
}

type PagesMenuProps = {
  reportSource: FragmentType<typeof fragments.PagesMenu_reportSource>
}

export const PagesMenu = ({ reportSource: _reportSource }: PagesMenuProps) => {
  const reportSource = getFragmentData(fragments.PagesMenu_reportSource, _reportSource)
  const [shownItems, setShownItems] = useState<number>()
  const [itemsWidth, setItemsWidth] = useState<number[]>()
  const { fillObject, setIsDefaultForm } = usePageTemplate()
  const matchPath = useMatchPath()
  const navigate = useNavigate()
  const { ref, width: wrapperWidth } = useElementSize()
  const buttonMoreItemsRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (ref.current) {
      const children = [...ref.current.childNodes]
      setItemsWidth(children.map(child => child.offsetWidth))
    }
  }, [ref])

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  useEffect(() => {
    if (itemsWidth && wrapperWidth && buttonMoreItemsRef.current) {
      let shownItems = countVisibleElements(itemsWidth, wrapperWidth)
      // We need space for the "More items" button
      if (shownItems < itemsWidth.length) {
        shownItems = countVisibleElements(
          itemsWidth,
          wrapperWidth - buttonMoreItemsRef.current.offsetWidth
        )
      }
      setShownItems(shownItems)
    }
  }, [itemsWidth, wrapperWidth])

  const pagesShown = useMemo(
    () => reportSource.pages.slice(0, shownItems),
    [reportSource, shownItems]
  )
  const pagesDropdown = useMemo(
    () => reportSource.pages.slice(shownItems),
    [reportSource, shownItems]
  )

  const activePageId: string | undefined = useMemo(() => {
    const activePage = reportSource.pages.find(page =>
      page.__typename === 'Page' ? matchPath(getPageRouteCallback(page)) : false
    )
    if (activePage?.__typename === 'Page') {
      return activePage.id
    }
    return undefined
  }, [matchPath, reportSource.pages])

  return (
    <Row ref={ref} className='flex-nowrap overflow-hidden flex-1 gap-4'>
      {pagesShown.map((page, index) => {
        if (page.__typename === 'Page') {
          return (
            <MenuItem
              key={page.id}
              page={page}
              onChangeElementWidth={(width: number) => {
                if (!itemsWidth || itemsWidth[index] !== width) {
                  setItemsWidth(prevState => {
                    const newState = prevState === undefined ? [] : [...prevState]
                    newState[index] = width
                    return newState
                  })
                }
              }}
            />
          )
        }
        return null
      })}
      <Dropdown
        menu={{
          selectedKeys: activePageId ? [activePageId] : [],
          items: pagesDropdown.map(page => {
            if (page.__typename === 'Page') {
              const { id, icon, title } = fillObject(page)
              const untitledIcon = pagesIcons[icon as PageIcon]
              const routeCallback = getPageRouteCallback(page)
              return {
                key: id,
                id,
                label: title,
                icon: untitledIcon ? <UntitledIcon icon={untitledIcon} /> : undefined,
                onClick: () => navigate(routeCallback),
              }
            }
            return null
          }),
        }}
      >
        <Button
          type='text'
          icon={<UntitledIcon icon={ulChevronDown} size={18} />}
          ref={buttonMoreItemsRef}
          className={pagesDropdown.length === 0 ? 'hidden' : ''}
        >
          <FormattedMessage {...messages.moreMenuItems} />
        </Button>
      </Dropdown>
    </Row>
  )
}
