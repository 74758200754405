import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { ReportSentContent } from './ReportSentContent'

const messages = defineMessages({
  testingDescription: 'FollowUp.SchoolReportSent.testingDescription',
})

const fragments = {
  ReportSentTestingCompany_publicReportSource: graphql(`
    fragment ReportSentTestingCompany_publicReportSource on PublicReportSource {
      id
      ...ReportSentContent_publicReportSource
    }
  `),
}

type Props = {
  channel: FragmentType<typeof fragments.ReportSentTestingCompany_publicReportSource>
}
export const ReportSentTestingCompany = ({ channel: _channel }: Props) => {
  const channel = getFragmentData(fragments.ReportSentTestingCompany_publicReportSource, _channel)
  return (
    <ReportSentContent
      channel={channel}
      description={<FormattedMessage {...messages.testingDescription} />}
    />
  )
}
