import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { ReportSentContent } from './ReportSentContent'
import { SchoolSentCollapse } from './SchoolSentCollapse'

const fragments = {
  ReportSentUnregisteredSchool_publicReportSource: graphql(`
    fragment ReportSentUnregisteredSchool_publicReportSource on PublicReportSource {
      id
      ...ReportSentContent_publicReportSource
    }
  `),
}

type Props = {
  channel: FragmentType<typeof fragments.ReportSentUnregisteredSchool_publicReportSource>
}
export const ReportSentUnregisteredSchool = ({ channel: _channel }: Props) => {
  const channel = getFragmentData(
    fragments.ReportSentUnregisteredSchool_publicReportSource,
    _channel
  )

  return (
    <ReportSentContent
      channel={channel}
      description={
        <>
          Bohužel, tvoje škola není v Nenech to být zaregistrována. Nemůžeme tedy zaručit, že se
          budou upozorněním zabývat. Zkusíme se s nimi ale spojit, aby se mu věnovat začali.
        </>
      }
    >
      <SchoolSentCollapse />
    </ReportSentContent>
  )
}
