import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { useNavigate } from '@faceup/router'
import { Button, Col, Row, Space, Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { useSavedReportsContext } from '../../hooks/useSavedReportsContext'
import { ReportInfo } from './components'

const messages = defineMessages({
  title: 'FollowUp.reports.title',
  checkAnotherReport: 'FollowUp.report.checkAnotherReport',
})

export const CheckReportList = () => {
  const { reports, loginIntoReport } = useSavedReportsContext()
  const navigate = useNavigate()

  return (
    <Space direction='vertical' size='large' className='w-full'>
      <Row justify='space-between' align='middle' className='mb-4'>
        <Col>
          <Typography.Title level={4}>
            <FormattedMessage {...messages.title} />
          </Typography.Title>
        </Col>
        <Col>
          <Button
            onClick={() => navigate(routes => routes.checkReportForm())}
            icon={<UntitledIcon icon={ulPlus} />}
          >
            <FormattedMessage {...messages.checkAnotherReport} />
          </Button>
        </Col>
      </Row>
      <Space direction='vertical' size='large' className='w-full'>
        {reports.map(report => (
          <ReportInfo
            key={report.id}
            report={report}
            onClick={async () => {
              await loginIntoReport(report)
              navigate(routes => routes.report())
            }}
          />
        ))}
      </Space>
    </Space>
  )
}
