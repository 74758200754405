import { TextInput, useForm, yup } from '@faceup/form'
import { useFormLogic } from '@faceup/form/src/Form/useFormLogic'
import { UntitledIcon } from '@faceup/icons'
import { usAlertTriangle } from '@faceup/icons/usAlertTriangle'
import { Form, MoreInfo } from '@faceup/ui'
import { Button, Checkbox, Col, Popconfirm, Row, Space, Tooltip } from '@faceup/ui-base'
import { ChannelType, isCompanyEmail as isCompanyEmailFn } from '@faceup/utils'
import { useEffect, useState } from 'react'
import { sharedMessages } from '../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  EmailNotificationsCheckbox_reportSource: graphql(`
    fragment EmailNotificationsCheckbox_reportSource on ReportSource {
      id
      sourceType
    }
  `),
  EmailNotificationsCheckbox_publicReportSource: graphql(`
    fragment EmailNotificationsCheckbox_publicReportSource on PublicReportSource {
      id
      sourceType
    }
  `),
}

const messages = defineMessages({
  edit: 'Shared.action.edit',
  emailPlaceholder: 'FollowUp.report.enterEmail',
  invalidEmail: 'FollowUp.report.notification.invalidEmail',
  verifyEmail: 'FollowUp.report.notification.verifyEmail',
  saveCompanyEmail: 'FollowUp.report.saveCompanyEmail',
})

const labelPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.survey.notification',
  [ChannelType.AutomatedHotline]: 'FollowUp.report.notification',
  [ChannelType.LiveHotline]: 'FollowUp.report.notification',
  [ChannelType.ReportingSystem]: 'FollowUp.report.notification',
})

const helperTextPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'FollowUp.survey.notificationInfo',
  [ChannelType.AutomatedHotline]: 'FollowUp.report.notificationInfo',
  [ChannelType.LiveHotline]: 'FollowUp.report.notificationInfo',
  [ChannelType.ReportingSystem]: 'FollowUp.report.notificationInfo',
})

type EmailNotificationsCheckboxAbstractProps = {
  isEmailNotificationEnabled: boolean
  onSubmit: (values: { email: string | null }) => boolean | Promise<boolean>
  email: string | null
  isEmailVerified: boolean | undefined
} & (
  | {
      channel: FragmentType<typeof fragments.EmailNotificationsCheckbox_reportSource>
      publicChannel?: never
    }
  | {
      publicChannel: FragmentType<typeof fragments.EmailNotificationsCheckbox_publicReportSource>
      channel?: never
    }
)

export const EmailNotificationsCheckboxAbstract = (
  props: EmailNotificationsCheckboxAbstractProps
) => {
  const {
    isEmailNotificationEnabled,
    onSubmit,
    email,
    isEmailVerified,
    channel: _channel,
    publicChannel: _publicChannel,
  } = props
  const [shouldNotify, setShouldNotify] = useState<boolean>(Boolean(email))
  const [isCompanyEmail, setIsCompanyEmail] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  })

  const { formatMessage } = useIntl()

  const form = useForm({
    schema,
    values: {
      email: email ?? '',
    },
    afterSubmit: 'persistValues',
  })

  const formLogic = useFormLogic({
    form,
    submitButtonText: 'save',
    onSubmit: async values => {
      const result = await onSubmit(values)
      if (result) {
        setIsEdit(false)
      }
      return result
    },
  })

  const emailValue = form.watch('email')

  useEffect(() => {
    const value = emailValue
    const checkIsCompanyEmail = async () => setIsCompanyEmail(await isCompanyEmailFn(value))
    void checkIsCompanyEmail()
  }, [emailValue])

  if (!isEmailNotificationEnabled) {
    return null
  }

  const channel = _channel
    ? getFragmentData(fragments.EmailNotificationsCheckbox_reportSource, _channel)
    : getFragmentData(fragments.EmailNotificationsCheckbox_publicReportSource, _publicChannel)

  const shouldShowVerificationAlert = isEmailVerified === false && Boolean(email)
  const isEmailFilled = Boolean(email)
  const showEditButton = isEmailFilled && !isEdit

  const submit = formLogic.onSubmit

  return (
    <Space direction='vertical'>
      <Checkbox
        checked={shouldNotify}
        onChange={e => {
          if (!e.target.checked) {
            void onSubmit({ email: null })
            form.setValue('email', '')
            formLogic.rawOnSubmit()
          }
          setShouldNotify(!shouldNotify)
        }}
      >
        <FormattedMessage {...labelPerChannel[channel.sourceType]} />{' '}
        <MoreInfo title={<FormattedMessage {...helperTextPerChannel[channel.sourceType]} />} />
      </Checkbox>
      {shouldNotify && (
        <Form
          onSubmit={e => {
            if (isCompanyEmail) {
              return
            }
            submit(e)
          }}
        >
          <Row gutter={8}>
            <Col flex='auto' className='relative'>
              <TextInput
                control={form.control}
                autoComplete='email'
                name='email'
                type='email'
                placeholder={formatMessage(messages.emailPlaceholder)}
                disabled={Boolean(email) && !isEdit}
                prefix={shouldShowVerificationAlert && <div className='w-4' />}
              />
              {shouldShowVerificationAlert && (
                <Tooltip title={<FormattedMessage {...messages.verifyEmail} />}>
                  <UntitledIcon
                    icon={usAlertTriangle}
                    className='absolute left-4 top-4 z-1 text-[#FA914B]'
                  />
                </Tooltip>
              )}
            </Col>
            {showEditButton ? (
              <Button data-test='edit-email' ghost type='primary' onClick={() => setIsEdit(true)}>
                <FormattedMessage {...messages.edit} />
              </Button>
            ) : (
              <>
                {isCompanyEmail ? (
                  <Popconfirm
                    title={formatMessage(messages.saveCompanyEmail)}
                    onConfirm={e => e && submit(e)}
                  >
                    <Button
                      type='primary'
                      data-test='save-email'
                      htmlType='submit'
                      loading={formLogic.isLoading}
                    >
                      <FormattedMessage {...sharedMessages.save} />
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    type='primary'
                    data-test='save-email'
                    htmlType='submit'
                    loading={formLogic.isLoading}
                    onClick={submit}
                  >
                    <FormattedMessage {...sharedMessages.save} />
                  </Button>
                )}
              </>
            )}
          </Row>
        </Form>
      )}
    </Space>
  )
}
