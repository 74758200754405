import { Layout } from '@faceup/ui-base'
import { LogoSize } from '@faceup/utils'
import { type ReactNode, useContext } from 'react'
import { LayoutContext } from '../Contexts/LayoutContext'
import Footer from './Footer'
import { Header } from './Header'
import InstitutionLogo from './InstitutionLogo'
import LanguageDropdown from './LanguageDropdown'

const { Content } = Layout

type Props = {
  children?: ReactNode
  isFooterHidden?: boolean
}

const PlainLayout = (props: Props) => {
  const { client } = useContext(LayoutContext)
  const hiddenFooter = props.isFooterHidden || client === 'iframe'

  return (
    <>
      {client !== 'iframe' && (
        <Header>
          <InstitutionLogo name={null} logoUrl={null} logoSize={LogoSize.md} />
          <LanguageDropdown />
        </Header>
      )}
      <Layout
        style={{
          minHeight: 'calc(100vh - 32px)',
          background: 'transparent',
        }}
      >
        <Content
          style={{ display: 'flex', flex: 1, flexDirection: 'column', padding: '32px 2em 20px' }}
        >
          {props.children}
        </Content>
        {!hiddenFooter && (
          <div className='mx-8'>
            <Footer />
          </div>
        )}
      </Layout>
    </>
  )
}

export default PlainLayout
