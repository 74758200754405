import { useContext, useEffect } from 'react'
import { LayoutContext } from '../../../../Contexts/LayoutContext'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { NavbarContent } from './NavbarContent'

const fragments = {
  InstitutionLayoutNavbar_reportSource: graphql(`
    fragment InstitutionLayoutNavbar_reportSource on PublicReportSource {
      id

      ...InstitutionLayoutNavbarContent_reportSource
    }
  `),
}

type NavbarProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutNavbar_reportSource>
  isOpened: boolean
}

export const Navbar = ({ reportSource: _reportSource, isOpened }: NavbarProps) => {
  const reportSource = getFragmentData(
    fragments.InstitutionLayoutNavbar_reportSource,
    _reportSource
  )
  const { client } = useContext(LayoutContext)

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden'
    } else {
      // default value is empty string
      document.body.style.overflow = ''
    }
    // On umnount set overflow to default value
    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpened])

  const isApp = client === 'app'
  return (
    <div
      className={`${isOpened ? 'fixed' : 'hidden'} top-[79px] left-0 right-0 overflow-scroll ${isApp ? `bottom-[111px]` : `bottom-[213px]`} bg-white z-50 p-3`}
    >
      <NavbarContent reportSource={reportSource} />
    </div>
  )
}
