import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { Popconfirm as AntPopconfirm, type PopconfirmProps as AntPopconfirmProps } from 'antd'

export type PopconfirmProps = AntPopconfirmProps

export const Popconfirm = ({ children, ...props }: PopconfirmProps) => {
  return (
    <AntPopconfirm
      {...props}
      icon={
        <span className='anticon'>
          {props.icon ? props.icon : <UntitledIcon icon={ulAlertCircle} />}
        </span>
      }
    >
      {children}
    </AntPopconfirm>
  )
}
