import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

/**
 * This is a temporary blockquote component,
 * so the blockquote rendered in follow-up looks
 * the same as the one rendered in the WYSIWYG editor.
 */
export const WysiwygBlockquote = ({ children }: Props) => {
  return (
    <Typography.Text>
      <blockquote>{children}</blockquote>
    </Typography.Text>
  )
}
