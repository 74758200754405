import { type RouteCallback, useNavigate } from '@faceup/router'
import { Lottie } from '@faceup/ui'
import { Button, Col, Row } from '@faceup/ui-base'
import { ChannelPageType, ChannelType } from '@faceup/utils'
import type { ReactNode } from 'react'
import PageTitle from '../../../../Components/PageTitle'
import { FormattedMessage, defineMessages } from '../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { useInstitution } from '../../../../hooks/useInstitution'
import { useSurveyPreview } from '../../../../hooks/useSurveyPreview'
import { sizeSettings } from '../../../../utils/constants'
import { useFilledPage } from '../../../Survey/useFilledPage'
import lottieCheckmark from './assets/lottie-checkmark.json'

const fragments = {
  ReportSentContent_publicReportSource: graphql(`
    fragment ReportSentContent_publicReportSource on PublicReportSource {
      id
      sourceType
      ...useFilledPage_reportSource
    }
  `),
}
const messages = defineMessages({
  backToHomepage: 'FollowUp.header.backToHomepage',
})

const messagesPerChannel = defineMessages<ChannelType>({
  [ChannelType.Survey]: 'Shared.surveys.detail.afterSendPageTitle',
  [ChannelType.AutomatedHotline]: 'Shared.CompanyReportSent.title',
  [ChannelType.LiveHotline]: 'Shared.CompanyReportSent.title',
  [ChannelType.ReportingSystem]: 'Shared.CompanyReportSent.title',
})

type ReportSentContentProps = {
  children?: ReactNode
  description?: ReactNode
  channel: FragmentType<typeof fragments.ReportSentContent_publicReportSource>
}

export const ReportSentContent = (props: ReportSentContentProps) => {
  const { children, description, channel: _channel } = props
  const channel = getFragmentData(fragments.ReportSentContent_publicReportSource, _channel)
  const institutionContext = useInstitution()
  const navigate = useNavigate()
  const filledPage = useFilledPage({ survey: channel, step: ChannelPageType.AfterSend })
  const { isPreview } = useSurveyPreview()

  return (
    <Row
      justify='center'
      data-test='ReportSent'
      style={{ pointerEvents: isPreview ? 'none' : 'auto' }}
    >
      <Col {...sizeSettings}>
        <div className='flex justify-center'>
          <div className='w-16'>
            <Lottie animationData={lottieCheckmark} shouldPlay />
          </div>
        </div>
        <PageTitle description={description}>
          {channel.sourceType !== ChannelType.Survey ? (
            <FormattedMessage {...messagesPerChannel[channel.sourceType]} />
          ) : (
            filledPage?.title
          )}
        </PageTitle>
        <div className='flex flex-col gap-8'>
          {children}
          {institutionContext?.homepage && (
            <div className='flex justify-center'>
              <Button
                onClick={() => navigate(institutionContext.homepage as RouteCallback)}
                data-test='report-sent-button-back-to-homepage'
              >
                <FormattedMessage {...messages.backToHomepage} />
              </Button>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}
