import { UntitledIcon } from '@faceup/icons'
import { useMatchPath, useNavigate } from '@faceup/router'
import { pagesIcons, usePageTemplate } from '@faceup/ui'
import { Button } from '@faceup/ui-base'
import type { ChannelPageType, PageIcon } from '@faceup/utils'
import { useElementSize } from '@mantine/hooks'
import { useEffect } from 'react'
import { getPageRouteCallback } from '../../../../../../../../utils/urls'

type MenuItemProps = {
  page: {
    id: string
    icon: string | null
    title: string | null
  } & ({ __typename: 'Page'; type?: never } | { __typename: 'ChannelPage'; type: ChannelPageType })
  onChangeElementWidth: (width: number) => void
}

export const MenuItem = (props: MenuItemProps) => {
  const { page, onChangeElementWidth } = props
  const { fillObject } = usePageTemplate()
  const matchPath = useMatchPath()
  const { ref, width } = useElementSize()
  const navigate = useNavigate()

  useEffect(() => {
    onChangeElementWidth(width)
  }, [width, onChangeElementWidth])

  const { id, icon, title } = fillObject(page)
  const untitledIcon = pagesIcons[icon as PageIcon]
  const routeCallback = getPageRouteCallback(page)
  const isItemActive = matchPath(routeCallback)
  return (
    <div ref={ref}>
      <Button
        key={id}
        onClick={() => navigate(routeCallback)}
        icon={untitledIcon ? <UntitledIcon icon={untitledIcon} /> : undefined}
        type={isItemActive ? 'link' : 'text'}
      >
        {title}
      </Button>
    </div>
  )
}
