import { Keyboard } from '@capacitor/keyboard'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import { ulFileSearch02 } from '@faceup/icons/ulFileSearch02'
import { ulHomeLine } from '@faceup/icons/ulHomeLine'
import { useMatchPath, useNavigate } from '@faceup/router'
import { UnstyledButton } from '@faceup/ui'
import { Divider, Row } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import { useElementSize } from '@mantine/hooks'
import { type ReactNode, useEffect, useState } from 'react'
import { FormattedMessage, defineMessages } from '../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { useInstitution } from '../../../../hooks/useInstitution'
import { useIsCheckReportAvailable } from '../../../../hooks/useIsCheckReportAvailable'
import LanguageDropdown from '../../../LanguageDropdown'
import { LogoutButton } from '../../../LogoutButton'

const messages = defineMessages({
  createReport: 'FollowUp.InstitutionLayout.createReport',
  checkReport: 'FollowUp.CheckReport.title',
  createReportForTestingSchool: 'FollowUp.InstitutionLayout.createReportForTestingSchool',
  home: 'FollowUp.InstitutionLayout.home',
})

const fragments = {
  AppFooter_reportSource: graphql(`
    fragment AppFooter_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage
      institution {
        id
        isTesting
        type
      }

      ...IsCheckReportAvailable_reportSource
    }
  `),
}

type AppFooterProps = {
  reportSource: FragmentType<typeof fragments.AppFooter_reportSource>
  shouldShowSecondaryMenu: boolean
}
export const AppFooter = ({
  reportSource: _reportSource,
  shouldShowSecondaryMenu,
}: AppFooterProps) => {
  const reportSource = getFragmentData(fragments.AppFooter_reportSource, _reportSource)
  const { ref: refFooter, height: heightFooter } = useElementSize()
  const { ref: refLanguageDropdown, height: heightLanguageDropdown } = useElementSize()
  const { isCheckReportAvailable } = useIsCheckReportAvailable({ reportSource })
  const matchPath = useMatchPath()
  const [isHidden, setIsHidden] = useState(false)
  const institution = useInstitution()
  const navigate = useNavigate()

  useEffect(() => {
    Keyboard.addListener('keyboardWillShow', () => setIsHidden(true))
    Keyboard.addListener('keyboardWillHide', () => setIsHidden(false))

    return () => {
      Keyboard.removeAllListeners()
    }
  }, [])

  if (isHidden) {
    return null
  }

  const height = heightFooter + (shouldShowSecondaryMenu ? heightLanguageDropdown : 0)
  const isTestingSchool =
    reportSource.institution.isTesting === true &&
    reportSource.institution.type === Institution.School

  const visibleMenuItems = [Boolean(institution?.homepage), true, isCheckReportAvailable]

  const countMenuItems = visibleMenuItems.filter(Boolean).length

  return (
    <div
      className='w-full fixed bottom-0 border-t-1 bg-white border-t-[#E6ECF9]'
      style={{ height }}
    >
      <div ref={refLanguageDropdown} className={shouldShowSecondaryMenu ? '' : 'hidden'}>
        <Row className='px-4 py-2 justify-between'>
          <LanguageDropdown
            availableLanguages={reportSource.languages}
            defaultLanguage={reportSource.defaultLanguage}
          />
          <LogoutButton size='small' />
        </Row>
        <Divider />
      </div>
      <div
        ref={refFooter}
        className='grid justify-between w-full pb-[env(safe-area-inset-bottom)]'
        style={{
          gridTemplateColumns: `repeat(${countMenuItems}, minmax(0, 1fr))`,
        }}
      >
        {institution?.homepage && (
          <ButtonItem
            onClick={() => navigate(institution.homepage)}
            active={Boolean(matchPath(routes => routes.page(), { wildcard: true }))}
            icon={ulHomeLine}
          >
            <FormattedMessage {...messages.home} />
          </ButtonItem>
        )}
        <ButtonItem
          onClick={() => navigate(routes => routes.createReport())}
          active={
            Boolean(matchPath(routes => routes.createReport())) ||
            Boolean(matchPath(routes => routes.sentReport()))
          }
          icon={ulEdit05}
        >
          {isTestingSchool ? (
            <FormattedMessage {...messages.createReportForTestingSchool} />
          ) : (
            <FormattedMessage {...messages.createReport} />
          )}
        </ButtonItem>
        {isCheckReportAvailable && (
          <ButtonItem
            onClick={() => navigate(routes => routes.checkReport())}
            active={
              Boolean(matchPath(routes => routes.report())) ||
              Boolean(matchPath(routes => routes.checkReport(), { wildcard: true }))
            }
            icon={ulFileSearch02}
          >
            <FormattedMessage {...messages.checkReport} />
          </ButtonItem>
        )}
      </div>
    </div>
  )
}

type ButtonItemProps = {
  onClick: () => void
  active: boolean
  icon: UntitledIconData
  children: ReactNode
}

const ButtonItem = ({ icon, onClick, active, children }: ButtonItemProps) => {
  return (
    <UnstyledButton onClick={onClick}>
      <div
        className={`py-3 px-1 flex flex-col gap-1 text-center whitespace-nowrap overflow-hidden ${active ? 'text-primary' : ''}`}
      >
        <UntitledIcon icon={icon} size={20} />
        <span className='overflow-hidden text-ellipsis'>{children}</span>
      </div>
    </UnstyledButton>
  )
}
