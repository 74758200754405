import { UntitledIcon } from '@faceup/icons'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Button } from '@faceup/ui-base'

type Props = {
  close: () => void
}

const Close = ({ close }: Props) => (
  <Button
    type='text'
    size='middle'
    icon={<UntitledIcon icon={ulXClose} color='#062D46' size={28} />}
    onClick={() => close()}
  />
)

export default Close
