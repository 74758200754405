import type { DeviceInfo } from '@capacitor/device'
import type { CaseRating, Institution } from '@faceup/utils'
import posthog from 'posthog-js'
import { useCallback, useEffect } from 'react'
import { getDeviceInfo } from '../locales'

const ALLOW_TRACKING = import.meta.env.VITE_ENVIRONMENT === 'production'

if (import.meta.env.VITE_POSTHOG_TOKEN) {
  posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
    api_host: 'https://eu.i.posthog.com',
    persistence: 'memory',
    autocapture: false,
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '.sensitive',
    },
    before_send: event => {
      if (!event) {
        return event
      }

      event.properties['$ip'] = '0.0.0.0'

      return event
    },
  })
}

// this function is the only function, which we need to be outside of the hook, because it is used in the `CustomApolloProvider.tsx`
export const reset = () => posthog.reset()

export const useTrackPlatform = () => {
  const { trackSetPlatform } = useAnalytics()

  useEffect(() => {
    const fetchPlatform = async () => {
      const { platform } = await getDeviceInfo()
      trackSetPlatform(platform)
    }

    fetchPlatform()
  }, [trackSetPlatform])
}

const useAnalytics = () => {
  const trackSetPlatform = (platform: DeviceInfo['platform']) => {
    posthog.capture('$set', {
      $set_once: { platform: platform.toLowerCase() },
    })
  }

  const trackCreateReportClicked = () => posthog.capture('Create report button clicked')
  const trackReportSent = () => posthog.capture('Report created')
  const trackReportPinCopied = () => posthog.capture('Report pin copied')
  const trackReportPinDownloaded = () => posthog.capture('Report pin downloaded')
  const trackSearchInstitutionInputClicked = (institutionType: Institution) =>
    posthog.capture('Search institution input clicked', {
      institutionType: institutionType.toLowerCase(),
    })
  const trackContinueButtonClicked = (institutionType: Institution) =>
    posthog.capture('Search institution continue button clicked', {
      institutionType: institutionType.toLowerCase(),
    })
  const trackScanQRCodeWindowOpened = () => {
    posthog.capture('Scan QR code window opened')
  }
  const trackQRCodeScanned = () => posthog.capture('QR code scanned')
  const trackFollowupMessageSent = () => posthog.capture('Follow-up message sent')
  const trackReportRatingSubmitted = (rating: CaseRating) =>
    posthog.capture('Report satisfaction rating submitted', { rating })

  const analytics = {
    trackSetPlatform: useCallback(trackSetPlatform, []),
    trackCreateReportClicked: useCallback(trackCreateReportClicked, []),
    trackReportSent: useCallback(trackReportSent, []),
    trackReportPinCopied: useCallback(trackReportPinCopied, []),
    trackReportPinDownloaded: useCallback(trackReportPinDownloaded, []),
    trackSearchInstitutionInputClicked: useCallback(trackSearchInstitutionInputClicked, []),
    trackContinueButtonClicked: useCallback(trackContinueButtonClicked, []),
    trackScanQRCodeWindowOpened: useCallback(trackScanQRCodeWindowOpened, []),
    trackQRCodeScanned: useCallback(trackQRCodeScanned, []),
    trackFollowupMessageSent: useCallback(trackFollowupMessageSent, []),
    trackReportRatingSubmitted: useCallback(trackReportRatingSubmitted, []),
  }

  if (ALLOW_TRACKING) {
    return analytics
  }

  const logger = (name: string, ...args: unknown[]) => console.info(`[Analytics]: ${name}`, args)

  const mockAnalytics = Object.keys(analytics).reduce(
    (acc, key) => ({
      ...acc,
      // biome-ignore lint/correctness/useHookAtTopLevel:
      [key]: useCallback((...args: unknown[]) => logger(key, args), [key]),
    }),
    {} as Record<keyof typeof analytics, () => void>
  )

  return mockAnalytics
}

export default useAnalytics
