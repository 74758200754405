import { type ReactElement, type ReactNode, useContext } from 'react'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { type LayoutVariant, panelWidth } from '../../utils/constants'
import Footer from '../Footer'

const fragments = {
  AppWrapper_config: graphql(`
    fragment AppWrapper_config on CompanyConfig {
      id
      ...Footer_config
    }
  `),
}

type AppWrapperProps = {
  children?: ReactNode
  variant?: LayoutVariant
  headerComponent: ReactElement
  navbarComponent?: ReactElement
  footerComponent?: ReactElement
  config?: FragmentType<typeof fragments.AppWrapper_config>
}

export const AppWrapper = ({
  children,
  variant = 'complex',
  headerComponent,
  navbarComponent,
  footerComponent,
  config: _config,
}: AppWrapperProps) => {
  const config = getFragmentData(fragments.AppWrapper_config, _config)
  const { client } = useContext(LayoutContext)

  const showBottomText = client !== 'iframe'

  return (
    <div>
      <div
        className='w-full pb-15 pt-[env(safe-area-inset-top)]'
        style={{ paddingBottom: client === 'app' ? 60 : 0 }}
      >
        <div>
          <div>{headerComponent}</div>

          <div className='flex justify-center w-full'>
            <div className='p-6 sm:w-[75%] sm:px-[6%] md:w-[60%] md:px-[3%] lg:px-[9%]'>
              {children}
              {showBottomText && (
                <div className='pt-12 md:pt-10'>
                  <Footer config={config} />
                </div>
              )}
            </div>
          </div>
          {navbarComponent && (
            <div style={{ width: panelWidth[variant]['md'] }}>{navbarComponent}</div>
          )}
          {footerComponent && <div>{footerComponent}</div>}
        </div>
      </div>
    </div>
  )
}
