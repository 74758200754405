import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Link, useNavigate } from '@faceup/router'
import { Button, Col, Row } from '@faceup/ui-base'
import { ChannelType } from '@faceup/utils'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { FormattedMessage, defineMessages } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { useInstitution } from '../../../../../hooks/useInstitution'
import LanguageDropdown from '../../../../LanguageDropdown'
import { PartnerInstitutionLogo } from './PartnerInstitutionLogo'

const messages = defineMessages({
  backToHomepage: 'FollowUp.header.backToHomepage',
})

const fragments = {
  HeaderSimple_reportSource: graphql(`
    fragment HeaderSimple_reportSource on PublicReportSource {
      id
      languages
      sourceType
      defaultLanguage
      ...PartnerInstitutionLogo_reportSource
    }
  `),
}

type HeaderSimpleProps = {
  reportSource: FragmentType<typeof fragments.HeaderSimple_reportSource>
  isHiddenBackButton?: boolean
}

export const HeaderSimple = ({
  reportSource: _reportSource,
  isHiddenBackButton: _isHiddenBackButton,
}: HeaderSimpleProps) => {
  const reportSource = getFragmentData(fragments.HeaderSimple_reportSource, _reportSource)
  const isHiddenBackButton =
    _isHiddenBackButton ?? reportSource.sourceType === ChannelType.AutomatedHotline
  const { client } = useContext(LayoutContext)
  const institutionContext = useInstitution()
  const navigate = useNavigate()
  const isApp = client === 'app'
  const homepageLinkCallback = institutionContext?.homepage ?? (routes => routes.home())

  return (
    <Row className='w-full justify-between items-center'>
      <Col span={8}>
        {!isApp && !isHiddenBackButton && (
          <>
            <div className={`hidden sm:block`}>
              <Button
                type='text'
                icon={<UntitledIcon icon={ulArrowLeft} size={24} />}
                onClick={() => navigate(homepageLinkCallback)}
                data-test='button-back-to-homepage'
              >
                <FormattedMessage {...messages.backToHomepage} />
              </Button>
            </div>
            <div className={`block sm:hidden`}>
              <Link to={homepageLinkCallback}>
                <Button
                  type='text'
                  icon={<UntitledIcon icon={ulArrowLeft} size={24} />}
                  data-test='button-back-to-homepage'
                />
              </Link>
            </div>
          </>
        )}
      </Col>
      <Col span={8}>
        <div className='flex justify-center'>
          <PartnerInstitutionLogo reportSource={reportSource} />
        </div>
      </Col>
      <Col span={8}>
        <div className='flex justify-end'>
          <div className={`hidden md:block`}>
            {(reportSource.languages ?? []).length > 1 && (
              <LanguageDropdown
                availableLanguages={reportSource.languages}
                defaultLanguage={reportSource.defaultLanguage}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}
