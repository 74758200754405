import type { LogoSize } from '@faceup/utils'
import { useContext } from 'react'
import { LayoutContext } from '../Contexts/LayoutContext'
import FaceUpLogo from '../assets/logo-fu.svg'
import NNTBLogo from '../assets/logo-nntb.svg'
import useConfigForProject from '../hooks/useConfigForProject'

const logoSizeStyle: Record<LogoSize, Record<'maxWidth' | 'maxHeight', number>> = {
  md: {
    maxWidth: 170,
    maxHeight: 44,
  },
  lg: {
    maxWidth: 170 * 1.5,
    maxHeight: 44 * 1.5,
  }, // header size 42 px
  xl: {
    maxWidth: 170 * 2,
    maxHeight: 44 * 2,
  }, // header size 54 px
}

type InstitutionLogoProps = {
  logoSize: LogoSize
  logoUrl: string | null
  name: string | null
}

const InstitutionLogo = ({ logoSize, logoUrl, name }: InstitutionLogoProps) => {
  const { theme } = useContext(LayoutContext)
  const { isNntb } = useConfigForProject()

  return (
    <img
      src={logoUrl ?? (isNntb ? NNTBLogo : FaceUpLogo)}
      alt={name ?? theme}
      className='w-auto'
      style={logoSizeStyle[logoSize]}
    />
  )
}

export default InstitutionLogo
