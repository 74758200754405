import { useNavigateGoBack } from '@faceup/router'
import { Button, Typography } from '@faceup/ui-base'
import PlainLayout from '../../Components/PlainLayout'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import OfflineIllustration from './assets/offline-illustration.svg'

const messages = defineMessages({
  title: 'FollowUp.offline.title',
  description: 'FollowUp.offline.description',
  button: 'FollowUp.offline.button',
})

const Offline = () => {
  const navigateGoBack = useNavigateGoBack()

  return (
    <PlainLayout>
      <div className='m-auto'>
        <div className='flex flex-col items-center gap-10'>
          <img src={OfflineIllustration} width='auto' height={180} alt='' />
          <div className='flex flex-col items-center gap-4'>
            <Typography.Title level={3}>
              <FormattedMessage {...messages.title} />
            </Typography.Title>

            <Typography.Text size='lg'>
              <FormattedMessage {...messages.description} />
            </Typography.Text>
          </div>
          <Button ghost type='primary' onClick={() => navigateGoBack(-1)}>
            <FormattedMessage {...messages.button} />
          </Button>
        </div>
      </div>
    </PlainLayout>
  )
}

export default Offline
